import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from 'moment';
import {EXPORT_ICON } from "assets/constants/Icons";

const ExcelExportComponent = (propsData) => {
    const handleExportButtonClick = async (propsData) => {
        let candidateDepartment = [];
        let employerUnit = [];
        let trainingParnter = [];
        let qualification = [];
        const metaData = JSON.parse(localStorage.getItem("metadata"));
        const Qualification =  metaData[0].meta_data.qualifications;
        const states = metaData[0].meta_data.states;
        const gender = ["Male","Female","Other"];
        const companies = metaData[0].meta_data.companies;
        const unitStatus = ["Active", "Pre-Opening"];
        const partners = ["THSC", "TIMES-PRO"];
        
        let state = [];
        states.forEach((item) => {
            state.push(item.state_name);
        })

        if(propsData.partnerSplitData || propsData.unitSplitData){
            for(let i in propsData.partnerSplitData){
                trainingParnter.push(i);
            }
            for(let i in propsData.unitSplitData){
                employerUnit.push(i);
                for(let j in propsData.unitSplitData[i]){
                    const foundDepartment = candidateDepartment.find((findItem) => findItem === j);
                    if(!foundDepartment){
                        candidateDepartment.push(j);   
                    }
                }
            }
            Qualification.forEach((item) => {
                qualification.push(item.education_name); 
            })
        }

        let data = propsData.data;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        let columns = [];
        propsData.columns.forEach((item) => {
            if (item.title !== "Project History") {
                columns.push({ header: item.title, key: item.field });
            }
        })

        try {
            const mainHeaderRow = worksheet.addRow(columns.map((column) => column.header));
            mainHeaderRow.font = { bold: true };

            if((propsData.partnerSplitData || propsData.unitSplitData) && data.length === 0){
                let keys = [];
                columns.forEach((item) =>{
                    keys.push(item.key);
                })
                const dataObject = {};
                keys.forEach((item) =>{
                    dataObject[`${item}`] ="";
                })
                data[0] = dataObject;
            }

            data.forEach((row) => {
                const dataRow = [];
                columns.forEach((column) => {
                    dataRow.push(row[column.key]);
                });
                worksheet.addRow(dataRow);
            });

            worksheet.columns.forEach(column => {
                column.eachCell(cell => {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    };
                });
            });

            if (propsData.candidateSplit || propsData.partnerSplitData || propsData.unitSplitData) {
                let validationColumnIndex = [];
                let validationData = [];
                columns.forEach((column, columnIndex) => {
                    if (column.header === "Department Selected") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(candidateDepartment);
                    }
                    if (column.header === "Training Partner" && trainingParnter.length > 0) {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(trainingParnter);
                    }
                    if (column.header === "Employer Unit") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(employerUnit);
                    }
                    if (column.header === "Highest Qualification") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(qualification);
                    }
                    if (column.header === "Gender") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(gender);
                    }
                    if (column.header === "Candidate State") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(state);
                    }
                    if (column.header === "DOB") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(["DD/MM/YYYY"]);
                    }
                });

                worksheet.columns.forEach((column, index) => {
                    if (validationColumnIndex.length > 0) {
                        validationColumnIndex.forEach((item, valIndex) => {
                            if (index === item) {
                                column.eachCell((cell, rowNumber) => {
                                    if (rowNumber > 1) {
                                        let stringData = validationData[valIndex].toString();
                                        if (stringData === "DD/MM/YYYY") {
                                            cell.dataValidation = {
                                                type: 'date',
                                                formulae: [new Date()],
                                                allowBlank: true,
                                                showInputMessage: true,
                                                promptTitle: 'Date Format',
                                                prompt: 'Format (DD/MM/YYYY) eg. 02/08/2023'
                                            }
                                        }
                                        else {
                                            stringData = `"${stringData}"`;
                                            cell.dataValidation = {
                                                type: 'list',
                                                allowBlank: true,
                                                formulae: [`${stringData}`],
                                                showErrorMessage: true,
                                                errorStyle: 'error',
                                                errorTitle: 'Error',
                                                error: 'Please Select from Drop Down'
                                            }
                                        }
                                    }
                                });
                            }
                        })
                    }
                });
            }

            if (propsData.employerModule) {
                let validationColumnIndex = [];
                let validationData = [];
                let companyData = [];
                companies.forEach((item) => {
                    companyData.push(item.company_name);
                })
                columns.forEach((column, columnIndex) => {
                    if (column.header === "Company Name") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(companyData);
                    }
                    if (column.header === "State") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(state);
                    }
                    if (column.header === "Unit Status") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(unitStatus);
                    }
                });

                worksheet.columns.forEach((column, index) => {
                    if (validationColumnIndex.length > 0) {
                        validationColumnIndex.forEach((item, valIndex) => {
                            if (index === item) {
                                column.eachCell((cell, rowNumber) => {
                                    if (rowNumber > 1) {
                                        let stringData = validationData[valIndex].toString();
                                        stringData = `"${stringData}"`;
                                        cell.dataValidation = {
                                            type: 'list',
                                            allowBlank: true,
                                            formulae: [`${stringData}`],
                                            showErrorMessage: true,
                                            errorStyle: 'error',
                                            errorTitle: 'Error',
                                            error: 'Please Select from Drop Down'
                                        }
                                    }
                                });
                            }
                        })
                    }
                });
            }

            if (propsData.partnerModule) {
                let validationColumnIndex = [];
                let validationData = [];
               
                columns.forEach((column, columnIndex) => {
                    if (column.header === "Partner Name") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(partners);
                    }
                    if (column.header === "State") {
                        validationColumnIndex.push(columnIndex);
                        validationData.push(state);
                    }
                });

                worksheet.columns.forEach((column, index) => {
                    if (validationColumnIndex.length > 0) {
                        validationColumnIndex.forEach((item, valIndex) => {
                            if (index === item) {
                                column.eachCell((cell, rowNumber) => {
                                    if (rowNumber > 1) {
                                        let stringData = validationData[valIndex].toString();
                                        stringData = `"${stringData}"`;
                                        cell.dataValidation = {
                                            type: 'list',
                                            allowBlank: true,
                                            formulae: [`${stringData}`],
                                            showErrorMessage: true,
                                            errorStyle: 'error',
                                            errorTitle: 'Error',
                                            error: 'Please Select from Drop Down'
                                        }
                                    }
                                });
                            }
                        })
                    }
                });
            }

            const firstRow = worksheet.getRow(1);
            firstRow.height = 30;
            const buffer = await workbook.xlsx.writeBuffer();
            const todayDate = moment(Date.now()).format("DD_MM_YYYY-HH_mm");
            let fileName = `${propsData.exportFileName} ${todayDate}.xlsx`; // Replace with the desired file name
            fileName = fileName.replaceAll(" ", "-");
            saveAs(new Blob([buffer]), fileName);
        }
        catch (error) {
            console.log("Error Occured while downloading Excel", error);
        }
    }
    return (
        <div className="material-table-export-excel-outer-div">
            <button className="material-table-export-excel" title="Export to Excel"
                onClick={() => {
                    handleExportButtonClick(propsData);
                }}
            >
            <i className={EXPORT_ICON} />
            </button>
        </div>
    );
};

export default ExcelExportComponent;