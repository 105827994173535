export const USER_ICON = "fa fa-user icon";
export const METER_ICON = "fa fa-tachometer icon";
export const ROUTE_ICON = "fa fa-road icon";
export const WIFI_1_ICON = "fa fa-wifi-1 icon";
export const WIFI_2_ICON = "fa fa-wifi-2 icon";
export const WIFI_ICON = "fa fa-wifi icon";
export const REFRESH_ICON = "fa fa-refresh icon";
export const SITE_ICON = "fa fa-sitemap icon";
export const CUSTOMER_ICON = "fa fa-user icon";
export const SORT_DOWN = "fa fa-caret-down icon";
export const SORT_UP = "fa fa-caret-up icon";
export const EDIT_ICON = "fa fa-edit icon";
export const TRASH_ICON = "fa fa-trash text-danger icon";
export const EYE_ICON = "fa fa-eye icon";
export const BOLT_ICON = "fa fa-bolt icon";
export const USER_PLUS_ICON = "fa fa-user-plus icon";
export const UNLINK_ICON = "fa fa-unlink icon";
export const CALENDAR_ICON = "fa-calendar icon";
export const MAP_MARKER_ICON = "fa fa-map-marker icon";
export const USERS_ICON = "fa fa-users icon";
export const VCARD_ICON = "fa fa-vcard-o icon";
export const LINE_CHART_ICON = "fa fa-line-chart icon";
export const SIGN_OUT_ICON = "fa fa-sign-out icon";
export const BAR_ICON = "fa fa-bars icon";
export const TIMES_CIRCLE_ICON = "fa fa-times-circle";
export const BACK_ARROW_ICON = "fa-angle-left";
export const CHECK_ICON = "fa fa-check icon";
export const CLOSE_ICON = "fa fa-close icon";
export const LOCK_ICON = "fa fa-lock ";
export const SAFARI_ICON = "fa fa-safari icon";
export const BILLING_ICON = "fa fa-credit-card icon";
export const SEARCH_ICON = "fa fa-search icon";
export const LINK_ICON = "fa fa-link icon";
export const FILE_ICON = "fa fa-file-text-o";
export const COPY_ICON = "fa fa-clipboard text-success bg-white icon fa-2x";
export const HISTORY_ICON = "fa fa-history icon";
export const GLOBE_ICON = "fa fa-globe icon";
export const ARROW_DOWN_ICON= "fa fa-arrow-down icon";
export const ARROW_UP_ICON = "fa fa-arrow-up icon";
export const PLUS_ICON = "fa fa-plus icon";
export const CALENDER_ICON ="fa fa-calendar icon";
export const ALIGNJUSTIFY_ICON = "fa fa-align-justify icon";
export const PLUS_SQUARE_ICON="fa fa-plus-square-o icon";
export const LIST_ALT_ICON="fa fa-list-alt icon";
export const LIST_ICON= "fa fa-list icon";
export const CHECK_ICON_STEP = "fa fa-check-circle icon fa-2x";
export const EMPLOYER = "fa fa-user-secret icon";
export const TRAININGPART = "fa fa-handshake-o icon";
export const TYPE_INFO = "fa fa-info-circle";
export const IMPORT_ICON = "fa fa-upload icon"
export const EXPORT_ICON = "fa fa-download icon";
export const PROGRAM_ICON = "fa fa-certificate icon";
export const STEPS_ICON = "fa fa-cubes icon";
export const MINUS_ICON = "fa fa-minus-circle icon";
export const DASH_ICON = "fa fa-minus icon";
export const EMAIL_ICON = "fa fa-envelope icon";
export const LOADING_SPIN = "fa fa-spinner icon fa-spin fa-3x fa-fw";
export const FILTER_ICON = "fa fa-filter icon";
export const EYE_SLASH_ICON = "fa fa-eye-slash icon";
export const KEY_ICON = "fa fa-key icon";
export const LOADING_SPIN_SMALL = "fa fa-spinner icon fa-spin fa-2x fa-fw";
export const COPY_ICON_SMALL = "fa fa-clipboard text-success bg-white icon";
export const UNDO_ICON = "fa fa-undo icon";