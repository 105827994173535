import React, { useState } from 'react';
import { downloadDocumentBucket } from 'lib/axiosUtils';

const FileDownloadWidget = (props) => {
  const { value, label } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Extract the S3 object key from URL
  const getS3Key = (url) => {
    if (!url) return null;

    const s3UrlPattern = /^https:\/\/([^/]+)\.s3\.amazonaws\.com\/(.+)$/; // Match any S3 bucket URL
    const match = url.match(s3UrlPattern);

    if (match && match[2]) {
      return match[2];
    }

    return url;
  };

  const handleDownload = async (e) => {
    e.preventDefault();

    const s3Key = getS3Key(value);

    if (!s3Key) {
      setError('Invalid file reference');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await downloadDocumentBucket({}, { fileKey: s3Key });

      if (!response.data?.downloadUrl) {
        throw new Error('Invalid response from server');
      }

      window.open(response.data.downloadUrl, '_blank');
    } catch (err) {
      console.error('Download failed:', err);
      setError(err.message || 'Failed to generate download link');

      if (value.startsWith('http')) {
        window.open(value, '_blank');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!value) return null;

  return (
    <div className="file-download-widget">
      {isLoading ? (
        <span>Preparing download...</span>
      ) : error ? (
        <div className="error-message">
          {error}
        </div>
      ) : (
        <button
          onClick={handleDownload}
          className="download-link-button"
          aria-label={`Download ${label.replace("Upload", "")}`}
        >
          {label.replace("Upload", "Download")}
        </button>
      )}
    </div>
  );
};

export default FileDownloadWidget;