import React, { useEffect, useState,useRef } from "react";
import { useHistory } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";
import { TYPE_SUCCESS, TYPE_FAIL, INTERVAL, SAMPLE_CANDIDATE_EXCEL, TYPE_INFO, BELOW_EIGHTEEN_COLOR } from "assets/constants/Constants";
import { EDIT_ICON, TRASH_ICON, EXPORT_ICON, IMPORT_ICON, EMAIL_ICON, HISTORY_ICON, LOADING_SPIN_SMALL } from "assets/constants/Icons";
import ShowToast from "components/ShowToast";
import ExcelExportComponent from "components/ExcelExportComponent";
import { hasPermission } from 'components/Permission';
import Spinner from 'components/Spinner';
import ImportErrorPopup from 'components/ImportErrorPopup';
import { registerBulkCandidate, validateCandidateData, getAge } from 'lib/utils';
import {importCandidate} from 'components/ImportCandidateComponent';
import { getEmployerList, getPartnerList, getCandidateList, deleteCandidate, resendEmailRegistration } from "lib/axiosUtils";
import CandidateHistoryPopupComponent from 'components/CandidateHistoryPopupComponent';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

const CandidatePage = () => {
    const history = useHistory();
    const [candidateData, setCandidateData] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const fileInputRef = useRef(null);
    const [employerList, setEmployerList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [importLoading, setImportLoading] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);
    const [candidateImportData, setCandidateImportData] = useState([]);
    const [candidateHistory, setCandidateHistory] = useState([]);
    const [showCandidateHistory, setShowCandidateHistory] = useState([]);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [historyLoading, setHistoryLoading] = useState(false);
    const mytheme =  createTheme({});

    useEffect(() => {
        fetchCandidateData();
    }, []);

    useEffect(() => {
        fetchEmployerList();
        fetchPartnerList();
    },[]);

    const cancelHandle = () =>{ 
        if(isModelOpen === true){
            setIsModelOpen(false);
            window.location.reload();
        }
    }

    const cancelHistoryModalHandle = () => {
        if (openHistoryModal === true) {
            setOpenHistoryModal(false);
        }
    }

    const fetchEmployerList = async () => {
        try {
            const data = await getEmployerList({},{})
            setEmployerList(data.data.result);
        }
        catch (error) {
            console.log("error while fetching data", error);
        }
    }

    const fetchPartnerList = async () => {
        try {
            const response = await getPartnerList({},{});
            setPartnerList(response.data.result);
        }
        catch (error) {
            console.log("Error Occurred while fetching training partner list", error);
        }
    }

    const fetchCandidateData = async () => {
        try {
            const data =  await getCandidateList({},{});
            let showCandidateHistory = [];
            data.data.result.forEach((item) => {
                showCandidateHistory.push(false);
                if (item.date_of_birth) {
                    let dateOfBirthForAge = new Date(item.date_of_birth).toLocaleDateString('en-IN', {
                        year: 'numeric',
                        day: '2-digit',
                        month: '2-digit'
                    });
                    const ageNumber = getAge(dateOfBirthForAge);
                    item.ageBelowEighteen = ageNumber >= 18 ? false : true;
                }
                else {
                    item.ageBelowEighteen = false;
                }
            })
            setShowCandidateHistory(showCandidateHistory);
            setCandidateData(data.data.result);
            setLoadingComplete(true);
        } catch (error) {
            console.log("Error while fetching data", error);
            setLoadingComplete(true);
        }
    };

    const addCandidate = () => {
        history.push("/add-candidate-page", { candidateId: null });
    };

    const handleCandidateExcelUpload = async(event) => {
        setImportLoading(true);
        try{
            if(event && event.target && event.target.files[0]){
                const requestData = await importCandidate(event, partnerList, employerList, "", [], true, false);
                if(requestData && requestData.length > 0){
                    const validateFlag = validateCandidateData(requestData);
                    let importData = null;
                    try {
                        if (validateFlag) {
                            importData = await registerBulkCandidate(requestData);
                        }
                        else {
                            setIsModelOpen(true);
                            setCandidateImportData(requestData)
                        }
                        if(importData){
                            await fetchCandidateData();
                        }
                        setImportLoading(false);
                    }
                    catch(error){
                        console.log("Error Occurred while importing candidate",error);
                        setImportLoading(false);
                    }
                }
                else{
                    ShowToast("No Candidate in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                    setImportLoading(false);
                }
            }
            else{
                ShowToast("Please select candidate excel for Importing", TYPE_INFO, INTERVAL, "", "");
                setImportLoading(false);
            }
        }
        catch(error){
            console.log("Error Occurred while importing",error);
            setImportLoading(false);
        }
    }

    const importCandidateExcel = async () => {
        fileInputRef.current.click();
    }

    const editCandidate = (data) => {
        history.push("/add-candidate-page", data);
    };

    const handleDelete = async (candidateId) => {
        if (window.confirm("CAUTION: Deleting this candidate will remove all data related to their tracker, history, projects, and multiple projects. Are you sure you want to delete this candidate from the system?")) {
            try {
                await deleteCandidate({},{},candidateId);
                await fetchCandidateData();
                ShowToast("Candidate deleted successfully", TYPE_SUCCESS, INTERVAL, "", "");
            } catch (error) {
                ShowToast("Something went wrong. Please delete again after sometime!", TYPE_FAIL, INTERVAL, "", "");
            }
        }
    };

    const handleProjectHistoryClick = async (rowData) => {
        try {
            setHistoryLoading(true);
            let showCandidatesHistory = [...showCandidateHistory];
            showCandidatesHistory = showCandidatesHistory.map((item) => {
                return false;
            })
            if (showCandidateHistory[rowData.tableData.id] === true) {
                showCandidatesHistory[rowData.tableData.id] = false;
            }
            else {
                showCandidatesHistory[rowData.tableData.id] = true;
            }
            setShowCandidateHistory(showCandidatesHistory);
            const historyData = [...rowData.candidateHistory];
            if (historyData && historyData.length > 0) {
                setCandidateHistory(historyData);
                setOpenHistoryModal(true);
            }
            else {
                setOpenHistoryModal(false);
                ShowToast("No History present", TYPE_SUCCESS, INTERVAL, "", "");
            }
            setHistoryLoading(false);
        }
        catch (error) {
            console.log("Error occurred while fetching the candidate project history", error);
            setHistoryLoading(false);
        }
    }

    const columns = [
        { title: "First Name", field: "firstName", cellStyle: { width: '12% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14 } },
        { title: "Last Name", field: "lastName", cellStyle: { width: '12%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Fathers Name", field: "fatherName", cellStyle: { width: '12%', color: '#808080', fontSize: 14 }, sorting: false },
        {
            title: "Project History",
            field: "history",
            cellStyle: { width: '12%', fontSize: 20 },
            render: (rowData) => (<>
                {
                    showCandidateHistory[rowData.tableData.id] === true && historyLoading === true ? (<>
                        <i className={`${LOADING_SPIN_SMALL} margin-left-15`} style={{ fontSize: 20 }}></i>
                    </>) : rowData && rowData.candidateHistory.length > 0 ? (
                        <i className={`${HISTORY_ICON} cursor-pointer margin-left-15`} onClick={() => handleProjectHistoryClick(rowData)} title="Project History"></i>
                    ) : (
                        <i className={`${HISTORY_ICON} cursor-pointer margin-left-15 default-grey-color`} onClick={() => handleProjectHistoryClick(rowData)} title="Project History"></i>
                    )
                }
            </>),
        },
        { title: "Gender", field: "gender", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        { title: "Department Selected", field: "departmentSelected", cellStyle: { width: '5%', color: '#808080', fontSize: 14 } },
        { title: "DOB", field: "disaplayDobDate", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Age", field: "ageNumber", cellStyle: { width: '5%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Highest Qualification", field: "qualificationsName", cellStyle: { width: '10%', color: '#808080', fontSize: 14 } },
        { title: "Email Id", field: "email", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Contact No", field: "mobileNumber", cellStyle: { width: '10%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Candidate City", field: "city", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } }, 
        { title: "Candidate State", field: "state", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Aadhar No.", field: "adharNo", cellStyle: { width: '10%', color: '#808080', fontSize: 14 }, sorting: false },
        { title: "Training Partner", field: "trainingPartner", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Employer Unit", field: "employerUnit", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Created Date", field: "createdDate", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } },
        { title: "Updated Date", field: "updatedDate", cellStyle: { width: '9%', color: '#808080', fontSize: 14 } }
    ];

    const actions = [
        hasPermission("candidates", "update") && (
            {
                icon: () => <i className={`${EDIT_ICON}`} title="Edit Candidate"></i>,
                onClick: (_, rowData) => editCandidate(rowData),
            }
        ),
        {
            icon: () => <i className={`${EMAIL_ICON}`} title="Resend TJP Registration Email"></i>,
            onClick: (_, rowData) => handleResendEmail(rowData),
        },
        {
            icon: () => <i className={`${TRASH_ICON}`} title="Delete Candidate"></i>,
            onClick: (_, rowData) => handleDelete(rowData.candidateId),
        },
    ];

    const downloadCandidateTemplateExcel = () => {
        window.open(SAMPLE_CANDIDATE_EXCEL, '_blank');
    }

    const handleResendEmail = async (candidateData) => {
        if (window.confirm("Are you sure you want to Resend TJP Registration email to this Candidate?")) {
            if (candidateData.email) {
                const requestData = {
                    email: candidateData.email
                }
                try {
                    const sendData = await resendEmailRegistration({}, requestData);
                    if (sendData.data.result.length > 0) {
                        ShowToast("Email sent successfully", TYPE_SUCCESS, INTERVAL, "", "");
                    }
                } catch (error) {
                    ShowToast("error while resend email to candidate", TYPE_FAIL, INTERVAL, "", "");
                }
            } else {
                ShowToast("No Email ID found to send email", TYPE_FAIL, INTERVAL, "", "");
            }
        }
    }

    return (<>
        {
            loadingComplete === false ? (
                <Spinner />
            ) : (
                <div className="main-content">
                    <div className="list-wrapper">
                        <div className="form-button-div">
                            {
                                hasPermission("candidates", "download_sample_template") && (
                                    <button className="button-secondary sample-file-width" onClick={downloadCandidateTemplateExcel}>Sample File&nbsp;&nbsp;&nbsp;<i className={IMPORT_ICON} /></button>
                                )
                            }
                            <button className="brand-button width-auto" onClick={addCandidate}>
                                Add Candidate
                    </button>
                            <input
                                type="file"
                                accept=".xlsx"
                                onChange={handleCandidateExcelUpload}
                                onClick = {(e) =>{ e.target.files[0] && handleCandidateExcelUpload(e)}}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                            />
                            {
                                hasPermission("candidates", "upload_candidate") && (
                                    importLoading === true ? (
                                        <div className="ml-5">
                                            <div className="spinner-loader">
                                            </div> 
                                        </div>
                                    ):(
                                        <button className="button-secondary width-auto ml-5" onClick={importCandidateExcel}>Import Candidate&nbsp;&nbsp;&nbsp;<i className={EXPORT_ICON} /></button>
                                    )
                                )
                            }

                        </div>
                        <div>
                            <div className="customer-button-div"></div>
                            <div className="mt-4 border list-table-div candidate-material-table-div program-candidate-material-table-div" style={{ marginBottom: 50 }}>
                                <ThemeProvider theme={mytheme}>
                                    <MaterialTable
                                        columns={columns}
                                        data={candidateData.map((item) => ({
                                            firstName: item.first_name,
                                            lastName: item.last_name,
                                            fatherName: item.fathers_name,
                                            disaplayDobDate: new Date(item.date_of_birth).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                            dobDate: item.date_of_birth,
                                            ageNumber: item.age,
                                            mobileNumber: item.mobile_no,
                                            adharNo: item.adhaar_no,
                                            email: item.email,
                                            qualificationsName: item.qualification,
                                            city: item.city,
                                            state: item.state,
                                            gender: item.gender,
                                            candidateId: item._id,
                                            departmentSelected: item.department_selected,
                                            trainingPartner: item.training_partner,
                                            employerUnit: item.employer_unit,
                                            employerId: item.employer_id,
                                            partnerId: item.partner_id,
                                            programId: item.program_id,
                                            ageBelowEighteen: item.ageBelowEighteen,
                                            createdDate: new Date(item.created_date_time).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                            updatedDate: new Date(item.updated_date_time).toLocaleDateString('en-IN', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            }),
                                            candidateHistory: item.candidateHistory
                                        }))}
                                        actions={actions}
                                        options={{
                                            actionsColumnIndex: -1,
                                            headerStyle: { color: "#999998", fontWeight: "bolder", fontSize: 15 },
                                            sorting: true,
                                            columnsButton: true,
                                            exportButton: true,
                                            exportFileName: "Candidate List",
                                            rowStyle: (rowData) => {
                                                return {
                                                    backgroundColor: rowData.ageBelowEighteen && BELOW_EIGHTEEN_COLOR,
                                                };
                                            }
                                        }}

                                        components={{
                                            Toolbar: (props) => (
                                                <div>
                                                    <MTableToolbar {...props} />
                                                    {
                                                        candidateData.length > 0 && loadingComplete === true && (
                                                            hasPermission("candidates", "download_excel") && (
                                                                <ExcelExportComponent {...props} />
                                                            )
                                                        )
                                                    }
                                                </div>
                                            ),
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    <CandidateHistoryPopupComponent modelOpen={openHistoryModal} closeModel={cancelHistoryModalHandle} candidateHistoryData={candidateHistory} />
    <ImportErrorPopup modelOpen={isModelOpen} closeModel={cancelHandle} candidateImportData = {candidateImportData}/>
    </>);
};

export default CandidatePage;