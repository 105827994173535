import * as XLSX from 'xlsx';
import { emailPattern } from "assets/constants/Constants";
import { getBulkCandidateByAadhaarArray } from "lib/axiosUtils";
import moment from 'moment-timezone';
import { detectDateFormat } from "lib/utils";

export function importCandidate(event, partnerList, employerList, programId, selectedCandidateList, candidateListFlag = false, trackingModule = false, fromTrackerPage = false) {
    return new Promise(async (resolve, reject) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0]; // First sheet
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, rawNumbers: true});
            let candidateList = [];
            let columns = [];
            if (jsonData.length > 0) {
                columns = trackingModule ? jsonData[1] : jsonData[0];
                columns = columns.map((item) => {
                    let itemElement = item.replaceAll(" ", "");
                    itemElement = itemElement.replaceAll(".", "");
                    itemElement = itemElement.trim();
                    itemElement = itemElement.toLowerCase();
                    return itemElement;
                });
                for (let i = (trackingModule ? 2 : 1); i < jsonData.length; i++) {
                    const keys = columns;
                    const values = jsonData[i].map((item, index) => {
                        if (item && typeof item === "string") {
                            return item.trim();
                        }
                        else if (item) {
                            return item;
                        }
                        else {
                            return "";
                        }
                    });
                    if(values && values.length > 0){
                        const result = keys.map((key, index) => ({ [key]: values[index] }))
                            .reduce((obj, item) => ({ ...obj, ...item }), {});
                        candidateList.push(result);
                    }
                }
            }
            let candidateParamsList = [];
            let candidateAadharCardArray = [];
            candidateList.forEach((item) =>{
                if(item.aadharno){
                    let aadharNo = item.aadharno;
                    if (typeof aadharNo === "string") {
                        aadharNo = aadharNo.trim();
                        aadharNo = aadharNo.replaceAll(" ", "");
                    }
                    candidateAadharCardArray.push(aadharNo);
                }
            })
            const arrayRequestData = {
                candidateAadhaarArray: candidateAadharCardArray
            }
            let candidateAadhaarPresentList = await getBulkCandidateByAadhaarArray({},arrayRequestData);
            candidateAadhaarPresentList = candidateAadhaarPresentList.data.result;
            const processCandidatePromises = candidateList.map(async (candidateItem) => {
                let params = {
                    firstName: "",
                    lastName: "",
                    fatherName: "",
                    adhaarNo: 0,
                    dobDate: "",
                    mobileNumber: 0,
                    qualification: "",
                    email: "",
                    gender: "",
                    state: "",
                    city: "",
                    age: "",
                    departmentSelected: "",
                    validCandidate: true,
                    errorMessage: [],
                    candidateListFlag: candidateListFlag,
                    programId: programId
                };
                if (candidateItem.firstname) {
                    params.firstName = candidateItem.firstname;
                }
                if (candidateItem.lastname) {
                    params.lastName = candidateItem.lastname;
                }
                if (candidateItem.fathersname) {
                    params.fatherName = candidateItem.fathersname;
                }
                if (candidateItem.aadharno) {
                    if(typeof candidateItem.aadharno ==="string"){
                        let adhaarNo = candidateItem.aadharno.trim();
                        adhaarNo = adhaarNo.replaceAll(" ", "");
                        params.adhaarNo = parseInt(adhaarNo);
                    }
                    else{
                        params.adhaarNo = candidateItem.aadharno;
                    }
                    const findRepeatedAdhaarCard = candidateParamsList.find((findItem) => params.adhaarNo === findItem.adhaarNo);
                    if(findRepeatedAdhaarCard){
                        params.validCandidate = false;
                        params.errorMessage.push("Aadhaar card number is already associated with other candidate in the excel");
                    }

                    if(candidateAadhaarPresentList.length > 0){
                        const isAadharPresent = candidateAadhaarPresentList.find((findItem) => findItem.adhaar_no.toString() === candidateItem.aadharno.toString());
                        if (isAadharPresent && candidateListFlag ===false) {
                            if (isAadharPresent.program_id && isAadharPresent.program_id !== programId) {
                                params.validCandidate = false;
                                params.errorMessage.push("Candidate is already associated with other Project");
                            }
                        }
                    }
                }
                else {
                    params.validCandidate = false;
                    params.errorMessage.push("Please enter adhaar number");
                }
                if (candidateItem.dob && typeof candidateItem.dob ==="string"){
                    const dateTest = candidateItem.dob.trim();
                    if (dateTest) {
                        const stringDate = candidateItem.dob.toString().trim();
                        if (stringDate) {
                            const detectedFormat = detectDateFormat(stringDate);
                            if (detectedFormat) {
                                const parsedDate = moment(stringDate, detectedFormat);
                                parsedDate.tz('Asia/Kolkata');
                                let formattedDate = parsedDate.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
                                formattedDate = formattedDate.replace('IST','India Standard Time');
                                params.dobDate = formattedDate;
                            } else {
                                params.validCandidate = false;
                                params.errorMessage.push("Please enter date in the format of 'dd/mm/yyyy'");
                            }
                        }
                        else {
                            params.validCandidate = false;
                            params.errorMessage.push("Please enter the valid date format. for eg 'dd/mm/yyyy'");
                        }
                    }
                
                }
                else if(candidateItem.dob){
                    const excelEpoch = new Date(1899, 11, 30);
                    const daysToAdd = Math.floor(candidateItem.dob);
                    const date = new Date(excelEpoch.getTime() + daysToAdd * 24 * 60 * 60 * 1000);

                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
                    const dateObj = new Date(year, month - 1, day, 0, 0, 0);
                    const timeZoneOffset = dateObj.getTimezoneOffset();
                    dateObj.setMinutes(dateObj.getMinutes() - timeZoneOffset);
                    if (month < 13) {
                        params.dobDate = dateObj;
                    }
                }
                if (candidateItem.contactno) {
                    if(typeof candidateItem.contactno ==="string"){
                        params.mobileNumber = parseInt(candidateItem.contactno);
                    }
                    else{
                        params.mobileNumber = candidateItem.contactno;
                    }
                }
                if (candidateItem.highestqualification) {
                    params.qualification = candidateItem.highestqualification;
                }
                if (candidateItem.emailid) {
                    if (!emailPattern.test(candidateItem.emailid)) {
                        params.validCandidate = false;
                        params.errorMessage.push("Please enter the valid emailId");
                    }
                    else {
                        params.email = candidateItem.emailid;
                    }
                }
                else {
                    params.validCandidate = false;
                    params.errorMessage.push("Please enter the emailId");
                }
                if (candidateItem.gender) {
                    if (candidateItem.gender.toLowerCase() === "male" || candidateItem.gender.toLowerCase() === "m") {
                        params.gender = "male";
                    }
                    else if (candidateItem.gender.toLowerCase() === "female" || candidateItem.gender.toLowerCase() === "f") {
                        params.gender = "female"
                    }
                    else {
                        params.gender = candidateItem.gender;
                    }
                } else {
                    params.validCandidate = false;
                    params.errorMessage.push("Please enter the Gender");
                }
                if (candidateItem.candidatestate) {
                    params.state = candidateItem.candidatestate;
                }
                if (candidateItem.candidatecity) {
                    params.city = candidateItem.candidatecity;
                }
                if (candidateItem.age) {
                    if(typeof candidateItem.age ==="string"){
                        params.age = parseInt(candidateItem.age);
                    }
                    else{
                        params.age = candidateItem.age;
                    }
                }
                if (candidateItem.departmentselected) {
                    params.departmentSelected = candidateItem.departmentselected;
                }
                if (candidateItem.trainingpartner) {
                    const trainingPartner = mapTrainingPartner(candidateItem.trainingpartner, partnerList);
                    if(trainingPartner && Object.entries(trainingPartner).length > 0){
                        params.trainingPartner = trainingPartner.trainingParnter;
                        params.partnerId = trainingPartner.partnerId;
                    }
                    else{
                        params.validCandidate = false;
                        params.errorMessage.push("Training Partner is not present in the system");
                    }
                }
                if (candidateItem.employerunit) {
                    const employerUnit = mapEmployerUnit(candidateItem.employerunit, employerList);
                    if(employerUnit && Object.entries(employerUnit).length > 0){
                        params.employerUnit = employerUnit.employerUnit;
                        params.employerId = employerUnit.employerId;
                    }
                    else{
                        params.validCandidate = false;
                        params.errorMessage.push("Employer Unit is not present in the system");
                    }
                }
                candidateParamsList.push(params);
                return params;
            });

            await Promise.all(processCandidatePromises);
            
            let requestData = [...candidateParamsList];
            if (requestData.length > 0) {
                requestData = checkAlreadySelectedCandidate(candidateParamsList, selectedCandidateList);
            }
            if (fromTrackerPage === true) {
                const requestDataObject = {
                    candidateList: requestData,
                    candidateAadhaarPresentList: candidateAadhaarPresentList
                }
                resolve(requestDataObject);
            }
            else {
                resolve(requestData);
            }
        };

        reader.onerror = (event) => {
            console.error("File could not be read:", event.target.error);
            resolve()
        };

        reader.readAsArrayBuffer(file);
    })
}

const mapTrainingPartner = (trainingPartner, partnerList) => {
    let tempPartner = trainingPartner.toLowerCase().trim();
    tempPartner = tempPartner.replaceAll(" ", "");
    let returnObject = {};
    const foundObject = partnerList.find((partnerItem) => {
        let itemTrainingPartner = "";
        if (partnerItem.training_partner) {
            itemTrainingPartner = partnerItem.training_partner.toLowerCase().trim();
            itemTrainingPartner = itemTrainingPartner.replaceAll(" ", "");
        }
        return itemTrainingPartner === tempPartner;
    });
    if (foundObject) {
        returnObject = {
            partnerId: foundObject._id,
            trainingParnter: foundObject.training_partner
        }
    }
    return returnObject;
}

const checkAlreadySelectedCandidate = (candidateArray, selectedCandidateList) => {
    let result = candidateArray.filter(item1 => !selectedCandidateList.some(item2 => item2.adhaar_no.toString() === item1.adhaarNo));
    return result;
}

const mapEmployerUnit = (employerUnit, employerList) => {
    let tempEmployerUnit = employerUnit.toLowerCase().trim();
    tempEmployerUnit = tempEmployerUnit.replaceAll(" ", "");
    let returnObject = {};
    const foundObject = employerList.find((employerItem) => {
        let itemEmployerUnit = "";
        if (employerItem.unit_name) {
            itemEmployerUnit = employerItem.unit_name.toLowerCase().trim();
            itemEmployerUnit = itemEmployerUnit.replaceAll(" ", "");
        }
        return itemEmployerUnit === tempEmployerUnit;
    });
    if (foundObject) {
        returnObject = {
            employerId: foundObject._id,
            employerUnit: foundObject.unit_name
        }
    }
    return returnObject;
}

export default importCandidate;