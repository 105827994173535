import React, { useEffect, useState, useMemo } from "react";
import MaterialTableProject from "components/Admin/MaterialTableProject";
import Spinner from 'components/Spinner';
import { getProjectwiseAdminData, getProgramList } from "lib/axiosUtils";

const AdminProjectDashboard = (props) => {
    const [totalsList, setTotalsList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const [projectWiseDataList, setProjectWiseDataList] = useState([]);

    useEffect(() => {
        if (props && props.projectTab === true) {
            if (props.projectTotalLoadingComplete === true) {
                fetchProjectWiseDataList();
            }
        }
        if (projectList.length === 0) {
            fetchProjectList();
        }
        setLoadingComplete(false);
        // eslint-disable-next-line
    }, [props])

    const fetchProjectWiseDataList = async () => {
        try {
            const data = await getProjectwiseAdminData({}, {}, props.projectType, props.selectedYear, props.selectedMonth);
            if (data && data.data && data.data.result) {
                setProjectWiseDataList(data.data.result);
                setFilteredList(data.data.result);
                createTotals(data.data.result.length);
            }
            setLoadingComplete(true);
        }
        catch (error) {
            console.log("Error Occurred while fetcing partner data list", error);
        }
    }

    const fetchProjectList = async () => {
        try {
            const response = await getProgramList({}, {});
            if (response && response.data && response.data.result) {
                setProjectList(response.data.result);
            }
        }
        catch (error) {
            console.log("Error Occurred while fetching project list", error);
        }
    }

    const createTotals = (unitTotals) => {
        let totalsList = [];
        const subsetTotalsObject = {
            rowName: "Subset Totals",
            totalUnits: unitTotals,
            totalActiveProject: props.totalProjectData.totalActiveProject,
            totalProjectCount: props.totalProjectData.totalProjectCount,
            totalProjectCompletedCount: props.totalProjectData.totalProjectCompletedCount,
            totalCandidateExpected: props.totalProjectData.totalCandidateExpected,
            totalCandidateDelivered: props.totalProjectData.totalCandidateDelivered,
            totalAssignedCandidate: props.totalProjectData.totalAssignedCandidate,
            totalCandidateDropout: props.totalProjectData.totalCandidateDropout,
            totalMaleCandidate: props.totalProjectData.totalMaleCount,
            totalFemaleCandidate: props.totalProjectData.totalFemaleCount,
            totalCandidateListed: props.totalProjectData.totalCandidateListed,
            postDeliveredDropout: props.totalProjectData.totalPostDeliverDropout,
        }
        totalsList.push(subsetTotalsObject);
        const percentageTotals = {
            rowName: "Percentage",
            totalActiveProject: props.totalProjectData.totalProjectCount !== 0 ? Math.round((props.totalProjectData.totalActiveProject / props.totalProjectData.totalProjectCount) * 100) : 0,
            totalProjectCompletedCount: props.totalProjectData.totalProjectCount !== 0 ? Math.round((props.totalProjectData.totalProjectCompletedCount / props.totalProjectData.totalProjectCount) * 100) : 0,
            totalCandidateDelivered: props.totalProjectData.totalCandidateExpected !== 0 ? Math.round((props.totalProjectData.totalCandidateDelivered / props.totalProjectData.totalCandidateExpected) * 100) : 0,
            totalCandidateDropout: props.totalProjectData.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalCandidateDropout / props.totalProjectData.totalAssignedCandidate) * 100) : 0,
            totalMaleCandidate: props.totalProjectData.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalMaleCandidate / props.totalProjectData.totalAssignedCandidate) * 100) : 0,
            totalFemaleCandidate: props.totalProjectData.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalFemaleCandidate / props.totalProjectData.totalAssignedCandidate) * 100) : 0,
            totalAssignedCandidate: props.totalProjectData.totalCandidateExpected !== 0 ? Math.round((props.totalProjectData.totalAssignedCandidate / props.totalProjectData.totalCandidateExpected) * 100) : 0,
            postDeliveredDropout: props.totalProjectData.totalCandidateDelivered !== 0 ? Math.round((props.totalProjectData.totalPostDeliverDropout / props.totalProjectData.totalCandidateDelivered) * 100) : 0
        }
        totalsList.push(percentageTotals);
        const averageProject = {
            rowName: "Average Per Project",
            totalCandidateExpected: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalCandidateExpected / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDelivered: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDropout: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalProjectCount) : 0,
            totalMaleCandidate: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalProjectCount) : 0,
            totalFemaleCandidate: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalProjectCount) : 0
        }
        totalsList.push(averageProject);
        setTotalsList(totalsList);
    }

    const handleFilterChange = (filter) => {
        let searchFilter = [...filter];
        if (searchFilter && searchFilter.length > 0) {
            let newUnitDataList = [];
            let unitListData = [];
            if (props && props.projectTab === true) {
                unitListData = [...projectWiseDataList];
            }
            unitListData = JSON.parse(JSON.stringify(unitListData));
            searchFilter.forEach((filterItem, index) => {
                if (filterItem.column && filterItem.column.field) {
                    if (index === 0) {
                        unitListData.forEach((listItem) => {
                            for (let i in listItem) {
                                if (i === filterItem.column.field) {
                                    let property = listItem[i].toString();
                                    property = property.toLowerCase();
                                    const filterValue = filterItem.value.toLowerCase();
                                    if (property.includes(filterValue)) {
                                        newUnitDataList.push(listItem);
                                    }
                                }
                            }
                        })
                    }
                    else {
                        let filteredList = [...newUnitDataList];
                        filteredList = JSON.parse(JSON.stringify(filteredList));
                        newUnitDataList = [];
                        filteredList.forEach((listItem) => {
                            for (let i in listItem) {
                                if (i === filterItem.column.field) {
                                    let property = listItem[i].toString();
                                    property = property.toLowerCase().replaceAll(" ", "");
                                    if (property.includes(filterItem.value)) {
                                        newUnitDataList.push(listItem);
                                    }
                                }
                            }
                        })
                    }
                }

            })
            const sendingDataList = [...newUnitDataList];
            setFilteredList(sendingDataList);
            updateTotalsByFilteredResult(sendingDataList);
        }
        else {
            if (props && props.projectTab === true) {
                createTotals(projectWiseDataList.length);
            }
        }
    }

    const updateTotalsByFilteredResult = (newUnitDataList) => {
        let totalProjectVariables = {};
        let totalCandidateAssigned = 0;
        let totalCandidateExpected = 0;
        let candidateDelivered = 0;
        let candidateDropout = 0;
        let totalMaleCandidate = 0;
        let totalFemaleCandidate = 0;
        let totalCompleteProject = 0;
        let totalCandidateListed = 0;
        let postDeliveredDropout = 0;

        newUnitDataList.forEach((listItem, index) => {
            totalCandidateAssigned = totalCandidateAssigned + listItem.candidateAssigned;
            totalCandidateExpected = totalCandidateExpected + listItem.candidateExpected;
            candidateDelivered = candidateDelivered + listItem.candidateDelivered;
            candidateDropout = candidateDropout + listItem.candidateDropout;
            totalMaleCandidate = totalMaleCandidate + listItem.totalMale;
            totalFemaleCandidate = totalFemaleCandidate + listItem.totalFemale;
            totalCompleteProject = totalCompleteProject + listItem.completeProject;
            totalCandidateListed = totalCandidateListed + listItem.candidateListed;
            postDeliveredDropout = postDeliveredDropout + listItem.postDeliveredDropout;
        })

        totalProjectVariables = {
            totalCompleteProject: totalCompleteProject,
            totalActiveProject: newUnitDataList.length - totalCompleteProject,
            totalCandidateAssigned: totalCandidateAssigned,
            totalCandidateExpected: totalCandidateExpected,
            candidateDelivered: candidateDelivered,
            candidateDropout: candidateDropout,
            totalMaleCandidate: totalMaleCandidate,
            totalFemaleCandidate: totalFemaleCandidate,
            totalCandidateListed: totalCandidateListed,
            postDeliveredDropout: postDeliveredDropout
        }
        let updateTotalsList = [];
        const subsetTotalsObject = {
            rowName: "Subset Totals",
            totalUnits: newUnitDataList.length,
            totalActiveProject: totalProjectVariables.totalActiveProject,
            totalProjectCount: newUnitDataList.length,
            totalProjectCompletedCount: totalProjectVariables.totalCompleteProject,
            totalCandidateExpected: totalProjectVariables.totalCandidateExpected,
            totalCandidateDelivered: totalProjectVariables.candidateDelivered,
            totalAssignedCandidate: totalProjectVariables.totalCandidateAssigned,
            totalCandidateDropout: candidateDropout,
            totalMaleCandidate: totalMaleCandidate,
            totalFemaleCandidate: totalFemaleCandidate,
            totalCandidateListed: totalProjectVariables.totalCandidateListed,
            postDeliveredDropout: totalProjectVariables.postDeliveredDropout
        }
        updateTotalsList.push(subsetTotalsObject);
        const percentageTotals = {
            rowName: "Percentage",
            totalActiveProject: subsetTotalsObject && subsetTotalsObject.totalActiveProject !== 0 ? Math.round((subsetTotalsObject.totalActiveProject / subsetTotalsObject.totalProjectCount) * 100) : 0,
            totalProjectCompletedCount: subsetTotalsObject && subsetTotalsObject.totalProjectCompletedCount !== 0 ? Math.round((subsetTotalsObject.totalProjectCompletedCount / subsetTotalsObject.totalProjectCount) * 100) : 0,
            totalCandidateDelivered: subsetTotalsObject && subsetTotalsObject.totalCandidateDelivered !== 0 ? Math.round((subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalCandidateExpected) * 100) : 0,
            totalCandidateDropout: subsetTotalsObject && subsetTotalsObject.totalCandidateDropout !== 0 ? Math.round((subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalMaleCandidate: subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalFemaleCandidate: subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalAssignedCandidate: subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalAssignedCandidate / subsetTotalsObject.totalCandidateExpected) * 100) : 0,
            postDeliveredDropout: subsetTotalsObject.postDeliveredDropout !== 0 ? Math.round((subsetTotalsObject.postDeliveredDropout / subsetTotalsObject.totalCandidateDelivered) * 100) : 0
        }
        updateTotalsList.push(percentageTotals);
        const averageProject = {
            rowName: "Average Per Project",
            totalCandidateExpected: subsetTotalsObject && subsetTotalsObject.totalCandidateExpected !== 0 ? Math.round(subsetTotalsObject.totalCandidateExpected / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDelivered: subsetTotalsObject && subsetTotalsObject.totalCandidateDelivered !== 0 ? Math.round(subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDropout: subsetTotalsObject && subsetTotalsObject.totalCandidateDropout !== 0 ? Math.round(subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalProjectCount) : 0,
            totalMaleCandidate: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalProjectCount) : 0,
            totalFemaleCandidate: subsetTotalsObject.totalProjectCount !== 0 ? Math.round(subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalProjectCount) : 0
        }
        updateTotalsList.push(averageProject);
        const listTotals = [...updateTotalsList];
        setTotalsList(listTotals);
    }

    const materialTableView = useMemo(() => {
        return (<MaterialTableProject projectWiseDataList={projectWiseDataList} handleFilterChange={handleFilterChange} totalsList={totalsList} filteredList={filteredList} projectList={projectList} employerTab={props.employerTab} projectType={props.projectType} projectTab={true} />)
        // eslint-disable-next-line
    }, [loadingComplete, projectWiseDataList])

    return (<>
        {
            loadingComplete === false ? (
                <Spinner />
            ) : (<>
                <div className="admin-employer-dashboard-outer-wrapper">
                    <table className="employer-partner-dashboard">
                        <thead>
                            <tr className="program-modal-main-row-header">
                                <th></th>
                                <th>Total Projects</th>
                                <th>Active Projects</th>
                                <th>Completed Projects</th>
                                <th>Candidates Expected</th>
                                <th>Candidates listed</th>
                                <th>Candidates Assigned</th>
                                <th>Candidates Delivered</th>
                                <th>Candidates Dropout</th>
                                <th>Post Delivered Dropout</th>
                                <th>Male Candidates</th>
                                <th>Female Candidates</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                totalsList.length > 0 && (
                                    totalsList.map((listItem, index) => {
                                        let rowClass = "";
                                        if (index % 2) {
                                        }
                                        else {
                                            rowClass = "program-modal-row-background-color"
                                        }
                                        return (
                                            <tr className={rowClass} key={`total-list-${index}`}>
                                                <td>{listItem.rowName}</td>
                                                <td>{listItem.totalProjectCount}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalActiveProject} %` : listItem.totalActiveProject}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalProjectCompletedCount} %` : listItem.totalProjectCompletedCount}</td>
                                                <td>{listItem.totalCandidateExpected}</td>
                                                <td>{listItem.totalCandidateListed}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalAssignedCandidate} %` : listItem.totalAssignedCandidate}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalCandidateDelivered} %` : listItem.totalCandidateDelivered}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalCandidateDropout} %` : listItem.totalCandidateDropout}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.postDeliveredDropout} %` : listItem.postDeliveredDropout}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalMaleCandidate} %` : listItem.totalMaleCandidate}</td>
                                                <td>{listItem.rowName === "Percentage" ? `${listItem.totalFemaleCandidate} %` : listItem.totalFemaleCandidate}</td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    </table>
                    {
                        ((projectWiseDataList && projectWiseDataList.length > 0)) && loadingComplete === true ? (<>
                            {materialTableView}
                        </>) : (<p className="mt-5">No Data to show</p>)
                    }
                </div>

            </>)
        }
    </>)
}

export default AdminProjectDashboard;