import React, { useEffect, useState, useRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { INTERVAL, TYPE_SUCCESS, TYPE_FAIL, DATE_PATTERN, DATE_FORMAT_IMPORT, DOCUMENTATION_STEP_ID, TIME_FORMAT_TWENTYFOUR, TIME_FORMAT_AMPM, SAMPLE_TRACKING_EXCEL, WEB_APP_URL, TYPE_INFO, INTERVIEWS_STEPS, INTERVIEWS_FINALPLACEMENT_STEP_ID, BELOW_EIGHTEEN_COLOR } from "assets/constants/Constants";
import { CHECK_ICON_STEP, IMPORT_ICON, EXPORT_ICON, LINK_ICON, UNDO_ICON} from "assets/constants/Icons";
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv6";
import "./program-tracker.css";
import { useParams } from 'react-router-dom';
import ShowToast from 'components/ShowToast';
import * as XLSX from 'xlsx';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from 'moment';
import FileDownloadWidget from "components/FileDownloadWidget";
import FileUploadWidget from "components/FileUploadWidget";
import Spinner from 'components/Spinner';
import { hasStepPermission } from 'components/Permission';
import { hasPermission } from "components/Permission";
import { fetchMetadata, sendDroppedEmailCandidate, registerBulkCandidate, validateCandidateData, detectDateFormat, decimalToTime, getAge } from "lib/utils";
import {
    getEmployerAndPartnerList, getProgramData, getCandidateByEmployer, getCandidatesByCandidateNames, getStepListByStepIdArray,
    getProgramTrackerData, createProgramTrackerData, saveTrackerExcelImportData, updateProgramCall, getCandidateByPartner,
    candidateInterviewFailedUpdate, candidateSelectFromFailedUpdate, updateProgramTrackerData, getUserDetails, revokeDropoutStatus, 
    getS3PresignedUrl, uploadDocumentBucket
} from "lib/axiosUtils";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { importCandidate } from "components/ImportCandidateComponent";
import ImportErrorPopup from "components/ImportErrorPopup";
import CompareDataModal from "components/ProjectTracking/CompareDataModal";
import AuditReport from "components/ProjectTracking/AuditReport";

const ProgramTracking = (props) => {
    const { id } = useParams();

    const [programId] = useState(id);
    const [data, setProgramData] = useState([]);
    const [candidateData, setCandidateData] = useState([]);
    const [stepsForms, setStepsForms] = useState([]);
    const [formData, setFormData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState("Phase 1");
    const [phases, setPhases] = useState([]);
    const [trackingData, setTrackingData] = useState([]);
    const [formCandidateId, setFormCandidateId] = useState("");
    const [viewStepData, setViewStepData] = useState(null);
    const [formDisable, setformDisable] = useState(false);
    const [trackingDataLoading, setTrackingDataLoading] = useState(false);
    const [importLoading, setImportLoading] = useState(false);
    const [documentStepsFlag, setdocumentStepsFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [metaData, setMetaData] = useState(JSON.parse(localStorage.getItem("metadata")) ? JSON.parse(localStorage.getItem("metadata")) : []);
    const [dropoutCandidateList, setDropoutCandidateList] = useState([]);
    const userDetail = JSON.parse(localStorage.getItem("userData"));
    const userRole = JSON.parse(localStorage.getItem("userRole"));
    const [formStepTitle, setFormStepTitle] = useState("");
    const [selectedPhaseNumber, setSelectedPhaseNumber] = useState(1);
    const [isErrorModelOpen, setIsErrorModelOpen] = useState(false);
    const [partnerList, setPartnerList] = useState([]);
    const [employerList, setEmployerList] = useState([]);
    const [candidateImportData, setCandidateImportData] = useState([]);
    const [compareModal, showCompareModal] = useState(false);
    const [candidateCompare, setCompareData] = useState([]);
    const [importCandidates, setCreateUpdateData] = useState([]);
    const [importingTrackingDataExcel, setImportedTrackingData] = useState([]);
    const [importNewCandidate, setNewCandidateFlag] = useState(false);
    const [formUpdateDataId, setFormUpdateTrackedId] = useState(null);
    const [isUploadFileStep, setUploadFileStep] = useState(false);
    const [docFormNavigation, setDocFormNavigationForward] = useState(true);
    
    const mytheme =  createTheme({});
    const fileInputRef = useRef(null);
    const hideSubmitButton = {
        "ui:submitButtonOptions": {
            "submitText": "Submit",
            "norender": true
        }
    };

    const headerToKeyMapping = {
        "Aadhar Document": "Aadhar",
        "10th Certificate Document": "10th Certificate",
        "12th Certificate Document": "12th Certificate",
        "Diploma / Degree Document": "Diploma / Degree"
    };

    useEffect(() => {
        fetchProgramData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        async function fetchMetaDataResult() {
            if (metaData && metaData.length === 0) {
                const metaDataResult = await fetchMetadata();
                setMetaData(metaDataResult);
            }
        }
        fetchMetaDataResult();
    }, [metaData]);

    useEffect(() => {
        if (formData.length > 0 && formData[0]._id === DOCUMENTATION_STEP_ID) {
            setUploadFileStep(true);
        }

        if (!showForm) {
            setUploadFileStep(false);
            setFormUpdateTrackedId(null);
        }
    }, [formData, showForm]);

    const fetchEmployerAndPartnerData = async () => {
        try {
            let employerDataList = [];
            let partnerDataList = [];
            if (employerList.length === 0 && partnerList.length === 0) {
                const result = await getEmployerAndPartnerList();
                employerDataList = result.data.result.employerList;
                partnerDataList = result.data.result.partnerList;
                setEmployerList(employerDataList);
                setPartnerList(partnerDataList);
            }
            else {
                employerDataList = [...employerList];
                partnerDataList = [...partnerList];
            }
            const returnObject = {
                partnerList: [...partnerDataList],
                employerList: [...employerDataList]
            }
            return returnObject;
        }
        catch (error) {
            console.log("Error Occurred while fetching employer list and partner list", error);
        }
    }

    const fetchProgramData = async () => {
        try {
            setIsLoading(true);
            const response = await getProgramData({},{},programId);
            const { result } = response.data;
            setProgramData([result]);

            if (selectedPhaseNumber === 1) {
                const uniquePhases = [...new Set(result.workflow_steps.map(item => item.step_phase))];
                setPhases(uniquePhases.sort());
                handlePhaseChange(uniquePhases[0]);
            }

            let getCandidateData = null;
            if ("employer_id" in userDetail && userDetail.employer_id) {
                const body = {
                    "employerId": userDetail.employer_id, 
                    "programId": programId 
                }
                getCandidateData = await getCandidateByEmployer({},body)
            } 
            else if("partner_id" in userDetail && userDetail.partner_id){
                const body = {
                    "partnerId": userDetail.partner_id, 
                    "programId": programId 
                }
                getCandidateData = await getCandidateByPartner({},body)
            }
            else {
                getCandidateData = await getCandidatesByCandidateNames({},result.candidate_id, programId);
            }

            setCandidateData(getCandidateData.data.result);

            const stepsIds = result.workflow_steps.map((steps) => steps.step_id);
            const getStepsForms = await getStepListByStepIdArray({},stepsIds);
            setStepsForms(getStepsForms.data.result);
            let dropOutStepList = [];
            getStepsForms.data.result.forEach((item) => {
                if (item.step_title.includes("Dropout") || item.step_title.includes("dropout")) {
                    if (item.step_title.includes("Phase") || item.step_title.includes("phase") || item.step_title.includes("Post Placement Dropout")) {
                        const listObject = {
                            ...item.form_schema,
                            step_id: item._id
                        }
                        dropOutStepList.push(listObject);
                    }
                }
            })

            // Get Ids of interview's step
            let stepIdInterviews = [];
            getStepsForms.data.result.forEach((item) => {
                INTERVIEWS_STEPS.forEach((interviewItem) => {
                    if (item.step_title === interviewItem) {
                        stepIdInterviews.push(item._id);
                    }
                })
            });

            const programTrackingData = await getProgramTrackerData({},{},programId);
            setTrackingData(programTrackingData.data.result);
            let candidateDropout = [];
            let candidatesInterviews = [];
            if (programTrackingData.data.result && programTrackingData.data.result.length > 0 && dropOutStepList.length > 0) {
                programTrackingData.data.result.forEach((trackItem) => {
                    const foundObject = dropOutStepList.find((item) => item.step_id === trackItem.step_id);
                    if (foundObject) {
                        const hasDropoutPhase = (
                            trackItem.data["dropout phase 1"] ||
                            trackItem.data["dropout phase 2"] ||
                            trackItem.data["dropout phase 3"] ||
                            trackItem.data["dropout phase 4"] ||
                            trackItem.data["dropout phase 5"] ||
                            trackItem.data["dropout post placement"]
                        );

                        if (hasDropoutPhase) {
                            candidateDropout.push(trackItem);
                        }
                    }

                    const foundInterview = stepIdInterviews.find((item) => item === trackItem.step_id);
                    if (foundInterview) {
                        const hasInterviewFailed = (
                            trackItem.data["Selected(Y/N)"] === false ||
                            trackItem.data["hr Employer Selection (Y/N)"] === false ||
                            trackItem.data["ops Employer Selection (Y/N)"] === false
                        );

                        if (hasInterviewFailed) {
                            candidatesInterviews.push(trackItem);
                        }
                    }
                })
            }
            let listCandidate = [];
            if (getCandidateData.data.result && getCandidateData.data.result.length > 0) {
                listCandidate = getCandidateData.data.result.map((item) => {
                    if (item.date_of_birth) {
                        let dateOfBirthForAge = new Date(item.date_of_birth).toLocaleDateString('en-IN', {
                            year: 'numeric',
                            day: '2-digit',
                            month: '2-digit'
                        });
                        const ageNumber = getAge(dateOfBirthForAge);
                        item.ageBelowEighteen = ageNumber >= 18 ? false : true;
                    }
                    else {
                        item.ageBelowEighteen = false;
                    }
                    const filteredDropoutCandidate = candidateDropout.filter((filterItem) => filterItem.candidate_id === item._id);
                    let dropoutArrayPhase = [];
                    filteredDropoutCandidate.forEach((filteredItem) => {
                        let checkPhaseDroppedout = Object.keys(filteredItem.data);
                        let droppedOutPhaseNumber = parseInt(checkPhaseDroppedout[0].match(/\d+/) ? checkPhaseDroppedout[0].match(/\d+/)[0] : "6");
                        dropoutArrayPhase.push(droppedOutPhaseNumber);
                    })
                    dropoutArrayPhase = dropoutArrayPhase.sort((a, b) => a - b);
                    if (filteredDropoutCandidate && filteredDropoutCandidate.length > 0 ) {
                        item.isDropout = true;
                        item.droppedOutPhase = dropoutArrayPhase[0];
                    }
                    else {
                        item.isDropout = false;
                    }

                    const foundCandidateInterviewFailed = candidatesInterviews.find((findItem) => findItem.candidate_id === item._id);
                    if (foundCandidateInterviewFailed) {
                        item.isFailedInterview = true;
                    } else {
                        item.isFailedInterview = false;
                    }

                    return item;
                })
            }
            setCandidateData(listCandidate);
            candidateDropout = candidateDropout.map((item) => {
                const findCandidate = listCandidate.find((findItem) => findItem._id === item.candidate_id)
                if (findCandidate) {
                    item.isDropout = findCandidate.isDropout;
                    item.droppedOutPhase = findCandidate.droppedOutPhase;
                }
                return item;
            })
            setDropoutCandidateList(candidateDropout);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.log("Error while fetching data", error);
        }
    };

    const undoDropoutStatus = async (rowItem) => {
        try {
            if (window.confirm(`Are you sure you want to Revoke/Undo Dropout Status for the candidate ${rowItem.candidateName} ?`)) {
                let requestData = {
                    candidateIdArray: [rowItem.candidateId],
                    programId: programId
                }
                const dropoutStatusData = await revokeDropoutStatus({}, requestData);
                if (dropoutStatusData && dropoutStatusData.statusCode === 200) {
                    if (dropoutStatusData.data.message !== "Not Revoked") {
                        ShowToast('Dropout status revoked successfully !', TYPE_SUCCESS, INTERVAL, props, "");
                        await fetchProgramData();
                        setFormUpdateTrackedId(null);
                    }
                    else {
                        ShowToast('Candidate is currently associated with other project', TYPE_SUCCESS, INTERVAL, props, "");
                    }
                }
            }
        }
        catch (error) {
            console.log("Error occurred while revoking the dropout status", error);
            ShowToast('Error occurred. Please try again after some time!', TYPE_FAIL, INTERVAL, props, "");
        }
    }

    const getDynamicColumns = (workflowSteps) => {
        let columns = [
            {
                title: "Sr. No", field: "srNo", cellStyle: { cellWidth: '2% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14, textAlign: 'left' }, headerStyle: { textAlign: 'left' },
                render: (rowData) => (
                    <>
                        {(!rowData.isDropout && !rowData.isFailedInterview) && (
                            <span>{rowData.srNo}</span>
                        )}
                        {(rowData.isDropout || rowData.isFailedInterview) && (
                            <span className="tick-icon-gray dropped-out-opacity-text">{rowData.srNo} </span>
                        )}
                    </>
                ),
            },
            {
                title: "Candidate Name",
                field: "candidateName",
                headerStyle: { textAlign: 'left' },
                cellStyle: { cellWidth: '14% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14, textAlign: 'left' },
                render: (rowData) => (
                    <>
                        {(!rowData.isDropout && !rowData.isFailedInterview) && (
                            <span>{rowData.candidateName}</span>
                        )}
                        {(rowData.isDropout || rowData.isFailedInterview) && (
                            <span className="tick-icon-gray dropped-out-opacity-text tracker-candidate-name-span">{rowData.candidateName} <i className={`${UNDO_ICON} list-table-icon undo-icon-class`} onClick={() => undoDropoutStatus(rowData)} title="Revoke Dropout"></i></span>
                        )}
                    </>
                ),
            },
            {
                title: "Training Partner", field: "trainingPartner", cellStyle: { cellWidth: '2% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14, textAlign: 'left' }, headerStyle: { textAlign: 'left' },
                render: (rowData) => (
                    <>
                        {(!rowData.isDropout && !rowData.isFailedInterview) && (
                            <span>{rowData.trainingPartner}</span>
                        )}
                        {(rowData.isDropout || rowData.isFailedInterview) && (
                            <span className="tick-icon-gray dropped-out-opacity-text">{rowData.trainingPartner}</span>
                        )}
                    </>
                ),
            },
            {
                title: "Employer Unit", field: "employerUnit", cellStyle: { cellWidth: '2% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14, textAlign: 'left' }, headerStyle: { textAlign: 'left' },
                render: (rowData) => (
                    <>
                        {(!rowData.isDropout && !rowData.isFailedInterview) && (
                            <span>{rowData.employerUnit}</span>
                        )}
                        {(rowData.isDropout || rowData.isFailedInterview) && (
                            <span className="tick-icon-gray dropped-out-opacity-text">{rowData.employerUnit}</span>
                        )}
                    </>
                ),
            },
            {
                title: "Department", field: "selectedDepartment", cellStyle: { cellWidth: '2% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14, textAlign: 'left' }, headerStyle: { textAlign: 'left' },
                render: (rowData) => (
                    <>
                        {(!rowData.isDropout && !rowData.isFailedInterview) && (
                            <span>{rowData.selectedDepartment}</span>
                        )}
                        {(rowData.isDropout || rowData.isFailedInterview) && (
                            <span className="tick-icon-gray dropped-out-opacity-text">{rowData.selectedDepartment}</span>
                        )}
                    </>
                ),
            },
        ]
        const dataColumns = workflowSteps.filter(step => step.step_phase === selectedPhase).sort((a, b) => a.step_no - b.step_no).map((step, index) => {
            return {
                title: step.stepTitle,
                cellStyle: { cellWidth: '12% !important', fontWeight: 'bolder', color: '#000000', fontSize: 14, textAlign: 'left' },
                render: rowData => {
                    const stepData = trackingData.filter(trackData => trackData.candidate_id === rowData.candidateId && trackData.step_id === step.step_id);

                    if (rowData.isDropout) {
                        if (selectedPhaseNumber > rowData.droppedOutPhase) {
                            return (
                                <i
                                    className={`${CHECK_ICON_STEP} ${`tick-icon-red dropped-out-opacity-icon`} icon-alignment`}
                                    title="Dropped Out"
                                    onClick={() => handleStepComplete(rowData, stepData, step)}
                                ></i>
                            )
                        } else if (selectedPhaseNumber === 6) {
                            return (
                                <i
                                    className={`${CHECK_ICON_STEP} ${`tick-icon-yellow dropped-out-opacity-icon`} icon-alignment`}
                                    title="Dropped Out"
                                    onClick={() => handleStepComplete(rowData, stepData, step)}
                                ></i>
                            )
                        }
                        else {
                            return (
                                <i
                                    className={`${CHECK_ICON_STEP} ${stepData.length > 0 ? "tick-icon-green " : "tick-icon-gray"} dropped-out-opacity-icon icon-alignment`}
                                    title={stepData.length > 0 ? "Step Completed" : "Click to Complete Step"}
                                    onClick={() => handleStepComplete(rowData, stepData, step)}
                                ></i>
                            )
                        }
                    }
                    else {
                        if (rowData.isFailedInterview) {
                            if (selectedPhaseNumber > 1) {
                                return (
                                    <i
                                        className={`${CHECK_ICON_STEP} ${stepData.length > 0 ? "tick-icon-green " : "tick-icon-orange"} dropped-out-opacity-icon icon-alignment`}
                                        title={stepData.length > 0 ? "Step Completed" : "Click to Complete Step"}
                                        onClick={() => handleStepComplete(rowData, stepData, step)}
                                    ></i>
                                )
                            } else {
                                return (
                                    <i
                                        className={`${CHECK_ICON_STEP} ${stepData.length > 0 ? "tick-icon-green " : "tick-icon-gray"} dropped-out-opacity-icon icon-alignment`}
                                        title={stepData.length > 0 ? "Step Completed" : "Click to Complete Step"}
                                        onClick={() => handleStepComplete(rowData, stepData, step)}
                                    ></i>
                                )
                            }
                        } else {
                            return (<>
                                <i
                                    className={`${CHECK_ICON_STEP} ${stepData.length > 0 ? "tick-icon-green" : "tick-icon-gray"} icon-alignment`}
                                    title={stepData.length > 0 ? "Step Completed" : "Click to Complete Step"}
                                    onClick={() => handleStepComplete(rowData, stepData, step)}
                                ></i>
                                <i className={`${LINK_ICON} step-complete-link`} title={"Copy Step Link"} onClick={() => handleLinkStep(rowData, stepData, step)}></i>
                            </>)
                        }
                    }
                }
            };
        });
        columns = [...columns, ...dataColumns];
        return columns;
    };

    const handleLinkStep = (rowData, stepData, step) => {
        navigator.clipboard.writeText(`${WEB_APP_URL}/step-complete/${step.step_id}/${programId}/${rowData.candidateId}`).then(() => {
            ShowToast('Link copied to clipboard successfully', TYPE_SUCCESS, INTERVAL, props, "");
        }, () => {
        });
    }

    const handleStepComplete = (rowData, stepData, step) => {
        if (hasStepPermission(step.stepTitle)) {
            const formShow = stepsForms.find(item => item._id === step.step_id);
            setFormData([formShow]);
            setFormCandidateId(rowData.candidateId);
            if (stepData.length === 0) {
                setViewStepData(null);
                setformDisable(false);
                setShowForm(true);
                setdocumentStepsFlag(false);
                setFormStepTitle(step.stepTitle);
            } else {
                if (INTERVIEWS_FINALPLACEMENT_STEP_ID.find((item) => item === formShow.step_id)) {
                    setFormUpdateTrackedId(stepData[0]._id);
                    setdocumentStepsFlag(false);
                    setViewStepData(stepData[0].data);
                    setformDisable(false);
                    setFormStepTitle(step.stepTitle);
                } else {
                    if (DOCUMENTATION_STEP_ID === formShow.step_id) {
                        setdocumentStepsFlag(true);
                    } else {
                        setdocumentStepsFlag(false);
                    }
                    setViewStepData(stepData[0].data);
                    setformDisable(true);
                }
                setShowForm(true);
            }
        } else {
            ShowToast("Not able to access", TYPE_FAIL, INTERVAL, props, "");
        }
    };

    const setDocumentStepsSchema = (formData, isUploading) => {
        return {
            ...formData[0].ui_schema,

            "ui:submitButtonOptions": {
                "submitText": "Submit",
                "norender": !isUploading
            },

            "Aadhar upload": {
                ...formData[0].ui_schema["Aadhar upload"],
                "ui:widget": isUploading ? FileUploadWidget : FileDownloadWidget,
                "ui:help": isUploading ? "Upload only PDF, PNG, JPG, JPEG" : ""
            },

            "10th certificate upload": {
                ...formData[0].ui_schema["10th certificate upload"],
                "ui:widget": isUploading ? FileUploadWidget : FileDownloadWidget,
                "ui:help": isUploading ? "Upload only PDF, PNG, JPG, JPEG" : ""
            },

            "12th certificate upload": {
                ...formData[0].ui_schema["12th certificate upload"],
                "ui:widget": isUploading ? FileUploadWidget : FileDownloadWidget,
                "ui:help": isUploading ? "Upload only PDF, PNG, JPG, JPEG" : ""
            },

            "diploma degree certificate upload": {
                ...formData[0].ui_schema["diploma degree certificate upload"],
                "ui:widget": isUploading ? FileUploadWidget : FileDownloadWidget,
                "ui:help": isUploading ? "Upload only PDF, PNG, JPG, JPEG" : ""
            }
        };
    };
    
    const handleSubmitForm = async (data) => {
        try {
            setIsSubmitting(true);
            const isDocumentFormSelected = formData[0]._id === DOCUMENTATION_STEP_ID ? true : false;
            let isAudit = false;
            const fetchCandidateData = candidateData.find(candidateItem => candidateItem._id === formCandidateId);
            const checkAuditFlag = await getUserDetails({}, {}, userDetail._id);
            if (checkAuditFlag.statusCode === 200) {
                isAudit = checkAuditFlag.data.result.audit_enable;
            }

            const updatedFormData = { ...data.formData };
            if (isDocumentFormSelected) {
                const uploadFields = [
                    "Aadhar upload",
                    "10th certificate upload",
                    "12th certificate upload",
                    "diploma degree certificate upload",
                ];

                // Loop through all upload fields
                for (const field of uploadFields) {
                    if (updatedFormData[field]) {
                        let file = updatedFormData[field];

                        // Convert Base64 to File
                        if (typeof file === "string" && file.startsWith("data:")) {
                            const mimeType = file.match(/data:(.*);base64/)[1];
                            const extension = mimeType.split("/")[1];
        
                            const blob = await fetch(file).then(res => res.blob());
                            file = new File([blob], `${field.replace(/\s+/g, "_")}.${extension}`, { type: mimeType });
                        }

                        const response = await getS3PresignedUrl({}, { fileName: file.name, fileType: file.type });
                        const { url, fileUrl } = response.data;

                        const headers = { 
                            "Content-Type": file.type,
                        };

                        await uploadDocumentBucket(headers, { url, file });
                        updatedFormData[field] = fileUrl;
                    }
                }
            }

            const candidateStepData = {
                programId: programId,
                candidateId: formCandidateId,
                stepId: formData[0]._id,
                stepStatus: "Completed",
                employerId: fetchCandidateData.employer_id,
                partnerId: fetchCandidateData.partner_id,
                data: isDocumentFormSelected ? updatedFormData : data.formData,
                userId: userDetail._id,
                userAudit: isAudit
            }

            if (formStepTitle.includes("Dropout") || formStepTitle.includes("dropout")) {
                const number = formStepTitle.match(/\d+/);
                if (number) {
                    const phaseNumber = parseInt(number[0]);
                    if (data.formData[`dropout phase ${phaseNumber}`]) {
                        const dropRequestData = {
                            ...fetchCandidateData,
                            programId: programId
                        };
                        await sendDroppedEmailCandidate(dropRequestData);
                    }
                }

                if (data.formData["dropout post placement"]) {
                    const dropRequestData = {
                        ...fetchCandidateData,
                        programId: programId
                    };

                    await sendDroppedEmailCandidate(dropRequestData);
                }
            }

            const foundInterviewStep = INTERVIEWS_STEPS.find(item => item === formData[0].step_title);
            if (foundInterviewStep) {
                const hasInterviewFailed = (
                    data.formData["Selected(Y/N)"] === false ||
                    data.formData["hr Employer Selection (Y/N)"] === false ||
                    data.formData["ops Employer Selection (Y/N)"] === false
                );

                if (hasInterviewFailed) {
                    await candidateInterviewFailedUpdate({}, candidateStepData);
                } else {
                    if (fetchCandidateData && fetchCandidateData.program_id === "") {
                        await candidateSelectFromFailedUpdate({}, candidateStepData);
                    }
                }
            }

            if (formUpdateDataId) {
                const stepCandidateUpdateData = await updateProgramTrackerData({}, candidateStepData, formUpdateDataId);
                if (stepCandidateUpdateData.statusCode === 200) {
                    ShowToast("Data Updated Successfully", TYPE_SUCCESS, INTERVAL, props, "");
                    setShowForm(false);
                    setUploadFileStep(false);
                    setFormUpdateTrackedId(null);
                    await fetchProgramData();
                } else {
                    setFormUpdateTrackedId(null);
                    setUploadFileStep(false);
                    ShowToast("Something went wrong", TYPE_FAIL, INTERVAL, props, "");
                }
            } else {
                const stepCandidateData = await createProgramTrackerData({}, candidateStepData);
                if (stepCandidateData.statusCode === 200) {
                    if (isDocumentFormSelected) {
                        ShowToast(
                            `Data saved successfully. ${docFormNavigation ? "Moving to the next candidate document." : "Moving to the previous candidate document."}`,
                            TYPE_SUCCESS,
                            INTERVAL,
                            props,
                            ""
                        );

                        docFormNavigation ? handleDocumentNavigation("next") : handleDocumentNavigation("prev");
                    } else {
                        ShowToast("Data saved Successfully", TYPE_SUCCESS, INTERVAL, props, "");
                        setShowForm(false);
                    }

                    setUploadFileStep(false);
                    await fetchProgramData();
                    setFormUpdateTrackedId(null);
                } else {
                    ShowToast("Something went wrong", TYPE_FAIL, INTERVAL, props, "");
                    setFormUpdateTrackedId(null);
                }
            }
        } catch (error) {
            console.log("Something went wrong", error);
        } finally {
            setIsSubmitting(false); // Whether successful or not, set submitting state back to false
        }
    }

    const handlePhaseChange = (phase) => {
        setSelectedPhase(phase);
        setSelectedPhaseNumber(parseInt(phase.match(/\d+/)[0]));
    };

    const importProgramTrackerExcel = () => {
        if (data.length > 0 && data[0].program_status && data[0].program_status === 'Completed' && userRole.user_role !== "super admin") {
            ShowToast("Project has been completed, You do not have permission to Import data", TYPE_FAIL, INTERVAL, props, "");
        } else {
            fileInputRef.current.click();
        }
    }

    const handleFileUpload = async (event) => {
        setImportLoading(true);
        try {
            if (event && event.target && event.target.files[0]) {
                const employerAndPartnerData = await fetchEmployerAndPartnerData();
                const requestDataResult = await importCandidate(event, employerAndPartnerData.partnerList, employerAndPartnerData.employerList, programId, [], false, true, true);
                const requestData = requestDataResult.candidateList;
                if (requestData && requestData.length > 0) {
                    const validateFlag = validateCandidateData(requestData);
                    try {
                        if (validateFlag) {
                            compareCandidateDataWithExisting(requestData,requestDataResult.candidateAadhaarPresentList)
                                .then((result) => {
                                    if (result.length > 0) {
                                        const comparedDataPresent = checkForAnyChanges(result);
                                        if (comparedDataPresent) {
                                            setCompareData(result);
                                            setCreateUpdateData(requestData);
                                            setImportedTrackingData(event);
                                            showCompareModal(true);
                                        } else {
                                            if (candidateData.length !== requestData.length) {
                                                saveCandidateData(event, requestData);
                                            } else {
                                                importTrackingData(event, candidateData);
                                            }
                                        }
                                    } else {
                                        ShowToast("No Candidate in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                                        setImportLoading(false);
                                    }
                                }).catch((err) => {
                                    console.log("Error while compare candidate data", err);
                                });
                        }
                        else {
                            setCandidateImportData(requestData);
                            setIsErrorModelOpen(true);
                        }
                    }
                    catch (error) {
                        console.log("Error Occurred while importing candidate", error);
                        setImportLoading(false);
                    }
                }
                else {
                    ShowToast("No Candidate in Excel for Importing", TYPE_INFO, INTERVAL, "", "");
                    setImportLoading(false);
                }
            }
            else {
                ShowToast("Please select candidate excel for Importing", TYPE_INFO, INTERVAL, "", "");
                setImportLoading(false);
            }
        }
        catch (error) {
            console.log("Error Occurred while importing", error);
            setImportLoading(false);
        }
    };

    const saveProgramTrackerData = (trackerData, updatedCandidateData) => {
        return new Promise((resolve, reject) => {
            let finalImportedData = [];
            const stepsNamesFromExcel = Object.keys(trackerData[0]);
            let dropoutEmailSentCandidate = [];

            trackerData.forEach((excelData) => {
                data[0]?.workflow_steps.forEach((stepItem) => {
                    if (hasStepPermission(stepItem.stepTitle)) {
                        let stepId = "";
                        const getStepId = stepsNamesFromExcel.includes(stepItem.stepTitle);

                        if (getStepId) {
                            stepId = stepItem.step_id;
                            let getCandidateId = updatedCandidateData.find(candidateItem => candidateItem.adhaar_no === excelData["Candidate Details"]["Aadhar No"]);
                            if (getCandidateId) {
                                const findDropped = dropoutCandidateList.some((findItem) => findItem.candidate_id === getCandidateId._id && findItem.isDropout === true);
                                if (!findDropped) {
                                    let checkDataExist = checkDataExistEmpty(excelData[stepItem.stepTitle]);
                                    if (checkDataExist) {
                                        let finalData = {};
                                        const getStepData = stepsForms.find(item => item._id === stepItem.step_id);
                                        const formProperties = getStepData.form_schema.properties;
                                        const fetchedData = excelData[stepItem.stepTitle];

                                        // Iterate over the "data" keys and compare with "properties" titles
                                        for (const key in fetchedData) {
                                            for (const propertyKey in formProperties) {
                                                // Use the mapped key if it exists, otherwise use the original key
                                                const mappedKey = headerToKeyMapping[key] || key;
    
                                                if (formProperties[propertyKey].title === mappedKey) {
                                                    if (fetchedData[key]) {
                                                        finalData[propertyKey] = fetchedData[key];
                                                    }
                                                }
                                            }
                                        }
                                        // Iterate over the object properties and replace "Yes" with true and "No" with false
                                        for (const key in finalData) {
                                            const value = finalData[key];

                                            if (typeof value === "string") {
                                                if (value.toLowerCase().trim() === "yes" || value.toLowerCase().trim() === "y") {
                                                    finalData[key] = true;
                                                } else if (value.toLowerCase().trim() === "no" || value.toLowerCase().trim() === "n") {
                                                    finalData[key] = false;
                                                }

                                                if (value.toLowerCase().trim() === "completed") {
                                                    finalData[key] = "Completed";
                                                } else if (value.toLowerCase().trim() === "pending") {
                                                    finalData[key] = "Pending";
                                                }

                                                if (value.toLowerCase().trim() === "accepted") {
                                                    finalData[key] = "Accepted";
                                                } else if (value.toLowerCase().trim() === "rejected") {
                                                    finalData[key] = "Rejected";
                                                }

                                                if (TIME_FORMAT_AMPM.test(value)) {
                                                    finalData[key] = convertTo24Hour(value);
                                                }
                                            }

                                            // Check date and change required format
                                            if (DATE_FORMAT_IMPORT.test(value)) {
                                                // Split the input date by "/"
                                                const [day, month, year] = value.split("/");

                                                // Create a new Date object in the desired format "YYYY-MM-DD"
                                                let outputDate = `${year}-${month}-${day}`;
                                                finalData[key] = outputDate;
                                            }
                                        }

                                        if (stepItem.stepTitle.includes("Dropout") || stepItem.stepTitle.includes("dropout")) {
                                            const number = stepItem.stepTitle.match(/\d+/);
                                            const emailSent = dropoutEmailSentCandidate.find((findItem) => findItem === getCandidateId._id);
                                            if (number) {
                                                const phaseNumber = parseInt(number[0]);
                                                if (finalData[`dropout phase ${phaseNumber}`]) {
                                                    const droppedRequestData = {
                                                        ...getCandidateId,
                                                        programId: programId
                                                    };
                                                    if (!emailSent) {
                                                        sendDroppedEmailCandidate(droppedRequestData);
                                                        dropoutEmailSentCandidate.push(getCandidateId._id);
                                                    }
                                                }
                                            }

                                            if (finalData["dropout post placement"]) {
                                                const droppedRequestData = {
                                                    ...getCandidateId,
                                                    programId: programId
                                                };
                                                if (!emailSent) {
                                                    sendDroppedEmailCandidate(droppedRequestData);
                                                    dropoutEmailSentCandidate.push(getCandidateId._id);
                                                }
                                            }
                                        }

                                        const candidateStepDataImport = {
                                            programId: programId,
                                            candidateId: getCandidateId._id,
                                            employerId: getCandidateId.employer_id,
                                            partnerId: getCandidateId.partner_id
                                        }

                                        const foundInterviewStep = INTERVIEWS_STEPS.find(item => item === stepItem.stepTitle);
                                        if (foundInterviewStep) {
                                            const hasInterviewFailed = (
                                                finalData["Selected(Y/N)"] === false ||
                                                finalData["hr Employer Selection (Y/N)"] === false ||
                                                finalData["ops Employer Selection (Y/N)"] === false
                                            );

                                            if (hasInterviewFailed) {
                                                candidateInterviewFailedUpdate({}, candidateStepDataImport);
                                            } else {
                                                if (getCandidateId.program_id === "") {
                                                    candidateSelectFromFailedUpdate({}, candidateStepDataImport);
                                                }
                                            }
                                        }                                        

                                        let trackData = {
                                            program_id: programId,
                                            candidate_id: getCandidateId._id,
                                            step_id: stepId,
                                            step_status: "batch upload excel",
                                            data: finalData,
                                            employer_id: getCandidateId.employer_id,
                                            partner_id: getCandidateId.partner_id
                                        };

                                        finalImportedData.push(trackData);
                                    }
                                }
                            }
                        }
                    }
                });
            });

            if (finalImportedData.length > 0) {
                resolve(finalImportedData);
            } else {
                reject("No data found.");
            }
        });
    };

    const checkDataExistEmpty = (data) => {
        for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] !== "") {
                return true;  // Found a non-empty value
            }
        }
        return false; // empty values found
    }
    const generateData = (excelTrackResult) => {
        let returnArray = [];
        candidateData.forEach((trackingItem, index) => {
            const date = new Date(trackingItem.date_of_birth);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
            const tempPartnerName = excelTrackResult.partnerData.find(item => item._id === trackingItem.partner_id);
            const tempEmployerUnit = excelTrackResult.employerData.find(item => item._id === trackingItem.employer_id);
            const findObject = dropoutCandidateList.find((findItem) => findItem.candidate_id === trackingItem._id && findItem.isDropout === true);
            const employerCompany = metaData[0].meta_data.companies.find((findItem) => findItem.company_id === excelTrackResult.company_id);
            if (userDetail) {
                if (!findObject && trackingItem.isFailedInterview === false) {
                    const returnObject = {
                        program: data.length > 0 ? data[0].program_name : "",
                        programCode: data.length > 0 ? data[0].program_code : "",
                        trainingpartner: tempPartnerName ? tempPartnerName.training_partner : null,
                        employerCompany: employerCompany ?  employerCompany.company_name: null,
                        unit: tempEmployerUnit ? tempEmployerUnit.unit_name : null,
                        firstName: trackingItem.first_name,
                        lastName: trackingItem.last_name,
                        fathersName: trackingItem.fathers_name,
                        gender: trackingItem.gender,
                        departmentSelected: trackingItem.department_selected ? trackingItem.department_selected : excelTrackResult.candidate_department,
                        dob: formattedDate,
                        age: trackingItem.age,
                        qualification: trackingItem.qualification,
                        email: trackingItem.email,
                        contactNo: trackingItem.mobile_no,
                        city: trackingItem.city,
                        state: trackingItem.state,
                        adhaarNo: trackingItem.adhaar_no,
                        candidateId: trackingItem._id
                    };
                    if (userDetail.user_role === "super admin") {
                        returnArray.push(returnObject);
                    }
                    else if (userDetail.employer_id && trackingItem.employer_id === userDetail.employer_id) {
                        returnArray.push(returnObject);
                    }
                    else if (userDetail.partner_id && trackingItem.partner_id === userDetail.partner_id){
                        returnArray.push(returnObject);
                    }
                }
            }
        })
        let trackArray = [];
        if (data && data.length > 0) {
            data[0].workflow_steps.forEach((stepItem, index) => {
                excelTrackResult.trackerData.forEach((item) => {
                    if (stepItem.step_id === item.step_id) {
                        const trackObject = {
                            stepTitle: stepItem.stepTitle,
                            stepId: stepItem.step_id,
                            candidateId: item.candidate_id,
                            trackData: item.data
                        }
                        trackArray.push(trackObject);
                    }
                })
            })
        }
        let finalArray = [];
        returnArray.forEach((returnItem) => {
            let returnObject = {
                ...returnItem
            };
            let stepTitleArray = [];
            trackArray.forEach((trackItem) => {
                if (returnItem.candidateId === trackItem.candidateId) {
                    stepTitleArray.push(trackItem.stepTitle);
                    returnObject.stepTitleArray = stepTitleArray;
                    returnObject = {
                        ...returnObject,
                        ...trackItem.trackData
                    }
                }
            })
            finalArray.push(returnObject);
        });
        finalArray = finalArray.map((item) => {
            Object.keys(item).forEach(key => {
                if (item[key] === true) {
                    item[key] = "Yes";
                }
                else if (item[key] === false) {
                    item[key] = "No"
                }
                if (DATE_PATTERN.test(item[key])) {
                    const [year, month, day] = item[key].split('-');
                    const formattedDate = `${day}/${month}/${year}`;
                    item[key] = formattedDate;
                }

                if (TIME_FORMAT_TWENTYFOUR.test(item[key])) {
                    // Split the time into hours, minutes, and seconds
                    const [hours, minutes] = item[key].split(':');

                    // Determine if it's AM or PM
                    const period = hours >= 12 ? 'PM' : 'AM';

                    // Convert hours to 12-hour format
                    const hours12 = (hours % 12) || 12;

                    // Construct the AM/PM time
                    const timeAMPM = `${hours12}:${minutes} ${period}`;

                    // Output the AM/PM time
                    item[key] = timeAMPM;
                }
            });
            return item;
        })
        finalArray.forEach((item, index) => {
            item.sno = index + 1;
            item.workflow_steps = excelTrackResult.workflow_steps;
            item.trackerData = excelTrackResult.trackerData;
        });
        return finalArray;
    };

    const addDynamicColumn = (data) => {
        let returnColumn = [];
        let columnKeys = [];
        stepsForms.forEach((stepItem) => {
            if (stepItem.form_schema && stepItem.form_schema.properties) {
                const keys = Object.keys(stepItem.form_schema.properties);
                const innerKeyValues = fetchInnerObjectKeyValues(stepItem.form_schema.properties);
                let values = [];
                innerKeyValues.forEach((item) => {
                    if (item.key === "title") {
                        values.push(item.value);
                    }
                })
                const keyValuePairs = keys.map((key, index) => ({ [key]: values[index] }));
                columnKeys.push(keyValuePairs);
            }
        })
        const flattenedArray = columnKeys.flat();

        returnColumn = flattenedArray.map((item) => {
            const key = Object.keys(item);
            const value = Object.values(item);
            return ({ header: value[0], key: key[0] });
        })

        return returnColumn;
    }

    const fetchInnerObjectKeyValues = (obj) => {
        const keyValues = [];

        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'object' && value !== null) {
                const innerKeyValues = fetchInnerObjectKeyValues(value);
                keyValues.push(...innerKeyValues);
            } else {
                keyValues.push({ key, value });
            }
        }

        return keyValues;
    }

    const programComplete = async () => {
        if (window.confirm("Are you sure you want to mark Status of this Project to Complete, This Action can not be undo")) {
            const programParams = {
                programStatus: "Completed"
            }
            try {
                await updateProgramCall({},programParams,programId);
                ShowToast('Project Marked Completed successfully', TYPE_SUCCESS, INTERVAL, props, "/project-list");
            }
            catch (error) {
                console.log("Error Occurred while Updating Project Status", error);
            }
        }
    }

    const prepareExcelTrackingData = async () => {
        let stepTitleArray = []
        if (data && data.length > 0) {
            data[0].workflow_steps.forEach((stepItem) => {
                const stepObject = {
                    stepTitle: stepItem.stepTitle,
                    stepNo: stepItem.step_no,
                    stepId: stepItem.step_id
                };
                stepTitleArray.push(stepObject);
            })
        }
        const employerAndPartnerData = await fetchEmployerAndPartnerData();
        const result = {
            ...data[0],
            stepTitleArray: stepTitleArray,
            candidateData: candidateData,
            trackerData: trackingData,
            employerData: employerAndPartnerData.employerList,
            partnerData: employerAndPartnerData.partnerList
        }
        return result;
    }

    const exportToExcel = async () => {
        setTrackingDataLoading(true);
        try {
            const excelTrackResult = await prepareExcelTrackingData();
            let employerInProject = [];
            let partnerInProject = [];
            data[0].employer_id.forEach((item) => {
                const findEmployer = excelTrackResult.employerData.find((findItem) => findItem._id === item);
                if (findEmployer) {
                    employerInProject.push(findEmployer.unit_name)
                }
            });
            data[0].partner_id.forEach((item) => {
                const findPartner = excelTrackResult.partnerData.find((findItem) => findItem._id === item);
                if (findPartner) {
                    partnerInProject.push(findPartner.training_partner)
                }
            });
            
            const exportdata = generateData(excelTrackResult);
            let arrayStep = [];
            stepsForms.forEach((formItem) => {
                excelTrackResult.stepTitleArray.forEach((item) => {
                    if (item.stepId === formItem._id) {
                        const pushObject = {
                            stepTitle: formItem.step_title,
                            stepNo: item.stepNo,
                            stepId: item.stepId,
                            formData: Object.keys(formItem.form_schema.properties),
                            innerKeyValues: fetchInnerObjectKeyValues(formItem.form_schema.properties)
                        }
                        arrayStep.push(pushObject);
                    }
                })
            });
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("Sheet 1");

            // Generate dynamic columns and subcolumns

            let dynamicColumn = addDynamicColumn(exportdata);
            dynamicColumn.forEach((dynamicItem) => {
                arrayStep.forEach((stepItem) => {
                    stepItem.formData.forEach((formItem) => {
                        if (formItem === dynamicItem.key) {
                            dynamicItem.stepNo = stepItem.stepNo;
                        }
                    })
                })
            });
            dynamicColumn.sort((a, b) => a.stepNo - b.stepNo);
            const columnDynamic = dynamicColumn;
            dynamicColumn = dynamicColumn.map((item) => {
                return ({ header: item.header, key: item.key });
            })

            // Map new header name to original title
            dynamicColumn.forEach((col) => {
                if (col.key === "Aadhar") {
                    col.header = "Aadhar Document";
                }

                if (col.key === "10thCertificate") {
                    col.header = "10th Certificate Document";
                }

                if (col.key === "12thCertificate") {
                    col.header = "12th Certificate Document";
                }

                if (col.key === "Diploma/Degree") {
                    col.header = "Diploma / Degree Document";
                }
            });

            let columns = [
                { header: "S.No", key: "sno" },
                // { header: "Program Code", key: "programCode" },
                { header: "Program", key: "program" },
                { header: "Training Partner", key: "trainingpartner" },
                { header: "Employer Company", key: "employerCompany" },
                { header: "Employer Unit", key: "unit" },
                { header: "First Name", key: "firstName" },
                { header: "Last Name", key: "lastName" },
                { header: "Fathers Name", key: "fathersName" },
                { header: "Gender", key: "gender" },
                { header: "Department Selected", key: "departmentSelected" },
                { header: "DOB", key: "dob" },
                { header: "Age", key: "age" },
                { header: "Highest Qualification", key: "qualification" },
                { header: "Email ID", key: "email" },
                { header: "Contact no", key: "contactNo" },
                { header: "Candidate City", key: "city" },
                { header: "Candidate State", key: "state" },
                { header: "Aadhar No", key: "adhaarNo" }
            ];
            columns = [...columns, ...dynamicColumn];

            const exportDataFilterArray = metaData[0].meta_data.export_data_filter_array;
            const filterResult = columns.filter(item1 => !exportDataFilterArray.some(item2 => item2 === item1.header));
            columns = [...filterResult];
            let subMainColumn = [];
            columns.forEach((item, index) => {
                subMainColumn.push("");
            })
            columns.forEach((columnItem, index) => {
                arrayStep.forEach((stepItem) => {
                    stepItem.formData.forEach((formItem) => {
                        if (formItem === columnItem.key) {
                            subMainColumn[index] = stepItem.stepTitle;
                        }
                    })
                })
            })
            for (let i = 5; i < 18; i++) {
                subMainColumn[i] = "Candidate Details";
            }

            let startIndex = [];
            let elementFound = false;
            for (let i = 0; i < subMainColumn.length; i++) {
                if (elementFound === false) {
                }
                if (subMainColumn[i] === subMainColumn[i + 1] && elementFound === false) {
                    elementFound = true;
                    startIndex.push(i);
                }
                else if (subMainColumn[i] !== subMainColumn[i + 1]) {
                    elementFound = false;
                    if (subMainColumn[i] !== subMainColumn[i + 1] && subMainColumn[i - 1] !== subMainColumn[i]) {
                        startIndex.push(i);
                    }
                }
            }
            startIndex.push(subMainColumn.length);
            const subcolumnRow = worksheet.addRow(subMainColumn.map((column) => column));
            subcolumnRow.font = { bold: true };

            for (let i = 0; i < startIndex.length - 1; i++) {
                let start = startIndex[i] + 1;
                let endIndex = startIndex[i + 1];
                try {
                    worksheet.mergeCells(1, start, 1, endIndex);
                    const headerCell = worksheet.getCell(1, start);
                    if (start === endIndex) {
                        headerCell.value = subMainColumn[start - 1];
                    }
                    else {
                        headerCell.value = subMainColumn[start];
                    }
                    let backGroundColor = "ffd3d3d3"

                    if (i % 2 === 0) {
                        backGroundColor = "ff3d6aa7";
                    }
                    if (i % 3 === 0) {
                        backGroundColor = "ffe4eff9";
                    }
                    if (subMainColumn[start - 1] === "Candidate Details") {
                        backGroundColor = "fffdd8ff";
                    }
                    if (subMainColumn[start - 1] === "Final Placement") {
                        backGroundColor = "ffb7d6a0";
                    }
                    headerCell.alignment = { vertical: "middle", horizontal: "center" };
                    headerCell.font = { bold: true };
                    headerCell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: backGroundColor }
                    };
                }
                catch (error) {
                    console.log("Error Occurred :", error);
                }
            }

            // Create the main header row
            const mainHeaderRow = worksheet.addRow(columns.map((column) => column.header));
            mainHeaderRow.font = { bold: true };

            // Add data rows
            let exportPermission = JSON.parse(localStorage.getItem("userRolePermission"));
            let stepPermitted = [];
            if (exportPermission) {
                exportPermission[0].roles.forEach((roleItem) => {
                    if (roleItem.role_name.replaceAll(" ", "").toLowerCase() === userDetail.user_role.replaceAll(" ", "").toLowerCase()) {
                        stepPermitted = fetchInnerObjectKeyValues(roleItem.permission.steps);
                    }
                })
            }

            let permittedStep = [];
            stepPermitted.forEach((item) => {
                if (item.value === true) {
                    arrayStep.forEach((stepItem) => {
                        if (stepItem.stepTitle === item.key) {
                            permittedStep.push(stepItem)
                        }
                    })
                }
            })
            exportdata.forEach((row) => {
                const dataRow = [];
                columns.forEach((column, index) => {
                    if (column.key !== "programCode") {
                        if (index > 17) {
                            const foundObject = columnDynamic.find((findItem) => column.key === findItem.key)
                            if (foundObject) {
                                const stepNoObject = permittedStep.find((findItem) => findItem.stepNo === foundObject.stepNo);
                                if (stepNoObject) {
                                    dataRow.push(row[column.key]);
                                }
                                else {
                                    dataRow.push("");
                                }
                            }
                        }
                        else {
                            dataRow.push(row[column.key]);
                        }
                    }
                });
                worksheet.addRow(dataRow);
            });
            // Set column widths
            columns.forEach((column, columnIndex) => {
                const actualColumnIndex = columnIndex + 1;
                worksheet.getColumn(actualColumnIndex).width = column.width;
            });

            worksheet.columns.forEach(column => {
                column.eachCell(cell => {
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" }
                    };
                });
            });
            let validationColumnIndex = [];
            let validationData = [];
            columns.forEach((column, columnIndex) => {
                stepsForms.forEach((stepItem) => {
                    if (stepItem.form_schema && stepItem.form_schema.properties) {
                        const entry = Object.entries(stepItem.form_schema.properties);
                        entry.forEach((item) => {
                            const originalTitle = headerToKeyMapping[column.header] || column.header;

                            if (originalTitle === item[1].title) {
                                if (item[1] && item[1].enum) {
                                    validationColumnIndex.push(columnIndex);
                                    validationData.push(item[1].enum);
                                }
                                else if (item[1].type === "boolean") {
                                    validationColumnIndex.push(columnIndex);
                                    validationData.push(["Yes", "No"]);
                                }
                                else if (item[1].type === "string" && item[1].format === "date") {
                                    validationColumnIndex.push(columnIndex);
                                    validationData.push(["DD/MM/YYYY"]);
                                }
                                //[NOTE]: Need this code when we will make changes for "time" while importing and exporting Excel for the tracking
                                else if (item[1].type === "string" && item[1].format === "time") {
                                    validationColumnIndex.push(columnIndex);
                                    validationData.push(["HH:MM AM/PM"]);
                                }
                            }
                        })
                    }
                })
                if (column.header === "Training Partner") {
                    validationColumnIndex.push(columnIndex);
                    validationData.push(partnerInProject);
                }
                if (column.header === "Employer Unit") {
                    validationColumnIndex.push(columnIndex);
                    validationData.push(employerInProject);
                }
            });

            worksheet.columns.forEach((column, index) => {
                if (validationColumnIndex.length > 0) {
                    validationColumnIndex.forEach((item, valIndex) => {
                        if (index === item) {
                            column.eachCell((cell, rowNumber) => {
                                if (rowNumber > 2) {
                                    let stringData = validationData[valIndex].toString();
                                    if (stringData === "DD/MM/YYYY") {
                                        cell.dataValidation = {
                                            type: 'date',
                                            formulae: [new Date()],
                                            allowBlank: true,
                                            showInputMessage: true,
                                            promptTitle: 'Date Format',
                                            prompt: 'Format (DD/MM/YYYY) eg. 02/08/2023'
                                        }
                                    }
                                    //[NOTE]: Need this code when we will make changes for "time" while importing and exporting Excel for the tracking
                                    else if (stringData === "HH:MM AM/PM") {
                                        cell.dataValidation = {
                                            type: 'date',
                                            formulae: [new Date()],
                                            allowBlank: true,
                                            showInputMessage: true,
                                            promptTitle: 'Time Format',
                                            prompt: 'Format (HH:MM AM/PM)'
                                        }
                                    }
                                    else {
                                        stringData = `"${stringData}"`;
                                        cell.dataValidation = {
                                            type: 'list',
                                            allowBlank: true,
                                            formulae: [`${stringData}`],
                                            showErrorMessage: true,
                                            errorStyle: 'error',
                                            errorTitle: 'Error',
                                            error: 'Please Select from Drop Down'
                                        }
                                    }
                                }
                            });
                        }
                    })
                }
            });
            worksheet.views = [
                {
                    state: "frozen",
                    xSplit: 7,
                    ySplit: 2
                }
            ]
            for (let i = 2; i <= 5; i++) {
                worksheet.getColumn(i).hidden = true;
            }

            const firstRow = worksheet.getRow(1);
            firstRow.height = 40;
            // Save the workbook as an Excel file
            if (exportdata[0]?.program) {
                const buffer = await workbook.xlsx.writeBuffer();
                const todayDate = moment(Date.now()).format("DD_MM_YYYY-HH_mm");
                let fileName = `${exportdata[0].program} ${todayDate}.xlsx`; // Replace with the desired file name
                fileName = fileName.replaceAll(" ", "-");
                saveAs(new Blob([buffer]), fileName);
            }
            else {
                ShowToast("No Data to Export(Dropped out candidate/s)", TYPE_FAIL, INTERVAL, props, "");
            }
            setTrackingDataLoading(false);
        }
        catch (error) {
            console.log("Error Occurred while exporting project tracker", error);
            setTrackingDataLoading(false);
        }
    };

    const downloadTemplateExcel = () => {
        window.open(SAMPLE_TRACKING_EXCEL, '_blank');
    }

    const convertTo24Hour = (time12Hour) => {
        const [time, period] = time12Hour.split(' ');
        const [hours, minutes] = time.split(':');
        let hoursInt = parseInt(hours);
        const minutesInt = parseInt(minutes);

        if (period === 'AM' && hoursInt === 12) {
            hoursInt = 0;
        } else if (period === 'PM' && hoursInt !== 12) {
            hoursInt += 12;
        }

        const formattedHours = hoursInt.toString().padStart(2, '0'); // Pad with leading zeros
        const formattedMinutes = minutesInt.toString().padStart(2, '0'); // Pad with leading zeros
        return `${formattedHours}:${formattedMinutes}:00`;
    }

    const cancelHandle = () => {
        if (isErrorModelOpen === true) {
            setIsErrorModelOpen(false);
            window.location.reload();
        }
    }

    const importTrackingData = async (event, updatedCandidateData) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0]; // First sheet
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const mainHeadersRow = jsonData[0]; // Main headers row
                const subHeadersRow = jsonData[1]; // Sub headers row

                let rowObjects = [];

                for (let rowIndex = 2; rowIndex < jsonData.length; rowIndex++) {
                    const dataRow = jsonData[rowIndex];

                    // Check if the row is empty
                    if (dataRow.every(cell => cell === "")) {
                        continue; // Skip this row if it's empty
                    }

                    const rowObject = {};

                    let currentMainHeader = '';

                    for (let i = 0; i < subHeadersRow.length; i++) {
                        const mainHeader = mainHeadersRow[i];
                        const subHeader = subHeadersRow[i];
                        const value = dataRow[i] || ''; // Put an empty string if the column value is empty

                        if (mainHeader) {
                            currentMainHeader = mainHeader;
                            rowObject[currentMainHeader] = {}; // Initialize the main header object
                        }

                        if (subHeader && currentMainHeader) {
                            rowObject[currentMainHeader][subHeader] = value;
                        }
                    }

                    rowObjects.push(rowObject);
                }

                const formatedDateAndTime = await formatDateAndTime(rowObjects);
                rowObjects = [...formatedDateAndTime];
                if (rowObjects.length > 0) {
                    saveProgramTrackerData(rowObjects, updatedCandidateData)
                        .then(async (importedData) => {
                            const importedDataFiltered = await compareCurrentAndPreviousTrackingData(importedData, trackingData);
                            if (importedDataFiltered.length > 0) {
                                const saveImportedData = await saveTrackerExcelImportData({}, importedDataFiltered);
                                if (saveImportedData.statusCode === 200) {
                                    ShowToast("Tracking data imported Successfully", TYPE_SUCCESS, INTERVAL, props, "");
                                    setImportLoading(false);
                                    await fetchProgramData();
                                } else {
                                    ShowToast("Something went wrong", TYPE_FAIL, INTERVAL, props, "");
                                    setImportLoading(false);
                                }
                            } else {
                                ShowToast("All Tracking data already updated", TYPE_SUCCESS, INTERVAL, props, "");
                            }
                        })
                        .catch(async (error) => {
                            console.error("Error while data set:", error);
                            ShowToast("No tracking data for importing", TYPE_INFO, INTERVAL, "", "");
                            setImportLoading(false);
                            await fetchProgramData();
                        });
                } else {
                    console.log("no data in excel");
                    setImportLoading(false);
                }
            } catch (error) {
                console.error("Error occurred while processing the file:", error);
                setImportLoading(false);
            }
        };

        reader.onerror = (event) => {
            console.error("File could not be read:", event.target.error);
        };

        reader.readAsArrayBuffer(file);
    }

    const formatDateAndTime = async (rowObjects) => {
        try {
            if (rowObjects && rowObjects.length > 0) {
                const datePattern = /\bdate\b/i;
                const timingPattern = /\btiming\b/i;
                rowObjects.forEach((rowItem) => {
                    for (let keyObject in rowItem) {
                        for (let key in rowItem[keyObject]) {
                            const foundDate = key.match(datePattern);
                            const foundTime = key.match(timingPattern);
                            if (foundDate) {
                                let excelDate = rowItem[keyObject][key];
                                if (excelDate && typeof excelDate === "string") {
                                    excelDate = excelDate.toString().trim();
                                    const detectedFormat = detectDateFormat(excelDate);
                                    if (detectedFormat) {
                                        const parsedDate = moment(excelDate, detectedFormat);
                                        parsedDate.tz('Asia/Kolkata');
                                        const formattedDate = parsedDate.format('YYYY-MM-DD');
                                        rowItem[keyObject][key] = formattedDate;
                                    }
                                }
                                else if (excelDate) {
                                    const dateExcel = new Date((excelDate) * 24 * 60 * 60 * 1000 + new Date(Date.UTC(1899, 11, 30)).getTime());
                                    const formattedDate = dateExcel.toISOString().split('T')[0];
                                    rowItem[keyObject][key] = formattedDate;
                                }
                            }
                            else if (foundTime && rowItem[keyObject][key]) {
                                const decimalTime = rowItem[keyObject][key];
                                if (typeof decimalTime === "number") {
                                    if (decimalTime !== Math.floor(decimalTime)) {
                                        const formattedTime = decimalToTime(decimalTime);
                                        rowItem[keyObject][key] = formattedTime;
                                    }
                                }
                            }
                        }
                    }
                })
            }
            return rowObjects;
        }
        catch (error) {
            console.log("Error occurred while formating date and time", error);
            return rowObjects;
        }
    }

    const compareCandidateDataWithExisting = async (requestData, candidateAadhaarPresentList) => {
        const changedFields = [];
        const currentCandidateData = [];

        if (requestData.length > 0) {
            requestData.forEach((existItem) => {
                let candidateObject = {
                    first_name: existItem.firstName ? existItem.firstName : "",
                    last_name: existItem.lastName ? existItem.lastName : "",
                    fathers_name: existItem.fatherName ? existItem.fatherName : "",
                    date_of_birth: existItem.dobDate ? formattedDate(existItem.dobDate) : "",
                    age: existItem.age ? existItem.age : "",
                    mobile_no: existItem.mobileNumber ? existItem.mobileNumber : "",
                    adhaar_no: existItem.adhaarNo ? existItem.adhaarNo : "",
                    email: existItem.email ? existItem.email : "",
                    gender: existItem.gender ? existItem.gender : "",
                    qualification: existItem.qualification ? existItem.qualification : "",
                    state: existItem.state ? existItem.state : "",
                    city: existItem.city ? existItem.city : "",
                    department_selected: existItem.departmentSelected ? existItem.departmentSelected : "",
                    employer_unit: existItem.employerUnit ? existItem.employerUnit : "",
                    training_partner: existItem.trainingPartner ? existItem.trainingPartner : ""
                };

                currentCandidateData.push(candidateObject);
            })
        }

        if (currentCandidateData.length > 0) {
            for (let i = 0; i < currentCandidateData.length; i++) {
                const requestObj = currentCandidateData[i];
                const candidateObj = candidateAadhaarPresentList.find(candidate => candidate.adhaar_no === requestObj.adhaar_no);

                if (candidateObj) {
                    const changedFieldsObj = {};
                    for (const key in requestObj) {
                        if (requestObj.hasOwnProperty(key) && candidateObj.hasOwnProperty(key)) {
                            if (candidateObj.program_id !== "") {
                                if (requestObj[key] !== candidateObj[key] && requestObj[key] && candidateObj[key]) {
                                    changedFieldsObj[key] = {
                                        updateValue: requestObj[key],
                                        previousValue: candidateObj[key]
                                    };
                                } else {
                                    changedFieldsObj[key] = {
                                        noValueChanged: requestObj[key] ? requestObj[key] : candidateObj[key] ? candidateObj[key] : ""
                                    };
                                }
                            } else {
                                changedFieldsObj[key] = {
                                    noValueChanged: candidateObj[key] ? candidateObj[key] : requestObj[key] ? requestObj[key] : ""
                                };
                            }
                        }
                    }
                    changedFields.push(changedFieldsObj);
                } else {
                    changedFields.push(currentCandidateData[i]);
                    setNewCandidateFlag(true);
                }
            }
        } else {
            return currentCandidateData;
        }

        return changedFields;
    }

    const cancelHandleCompare = () => {
        if (compareModal === true) {
            showCompareModal(false);
            window.location.reload();
        }
    }

    const formattedDate = (dateString) => {
        const dateObject = new Date(dateString);
        const isoDateString = dateObject.toISOString();
        return isoDateString
    }

    const saveCandidateData = async (eventExcelData, requestData) => {
        const importData = await registerBulkCandidate(requestData ? requestData : importCandidates);

        if (importData && importData.length > 0) {
            if (importNewCandidate) {
                let listCandidate = [];
                listCandidate = importData.map((item) => {
                    const foundCandidate = dropoutCandidateList.find((findItem) => findItem.candidate_id === item._id)
                    if (foundCandidate) {
                        let checkPhaseDroppedout = Object.keys(foundCandidate.data);
                        let droppedOutPhaseNumber = parseInt(checkPhaseDroppedout[0].match(/\d+/)[0]);

                        item.isDropout = true;
                        item.droppedOutPhase = droppedOutPhaseNumber;
                        return item;
                    }
                    else {
                        item.isDropout = false;
                        return item;
                    }
                });

                await importTrackingData(importingTrackingDataExcel, listCandidate);
                showCompareModal(false);

            } else {
                await importTrackingData((eventExcelData ? eventExcelData : importingTrackingDataExcel), (eventExcelData ? importData : candidateData));
                showCompareModal(false);
            }
        } else {
            ShowToast("No Candidate in Excel", TYPE_INFO, INTERVAL, "", "");
        }
    }

    const checkForAnyChanges = (compareCandidateData) => {
        let isAnyDataToShow = false;

        compareCandidateData.forEach((data) => {
            if (typeof data.first_name === "string") {
                return isAnyDataToShow = true;
            } else {
                for (const key in data) {
                    const valueObj = data[key];
                    if (valueObj.hasOwnProperty("updateValue")) {
                        return isAnyDataToShow = true;
                    }
                }
            }
        })
        return isAnyDataToShow;
    }

    const compareCurrentAndPreviousTrackingData = async (importedDataArray, trackingDataArray) => {
        let differences = [];
        let newDiffreneces = [];
        let isAudit = false;
        const checkAuditFlag = await getUserDetails({}, {}, userDetail._id);
        if (checkAuditFlag.statusCode === 200) {
            isAudit = checkAuditFlag.data.result.audit_enable;
        }

        for (let item1 of importedDataArray) {
            for (let item2 of trackingDataArray) {
                if (item1.candidate_id === item2.candidate_id && item1.step_id === item2.step_id) {
                    if (isDifferent(item1, item2)) {
                        differences.push({ ...item1, user_id: userDetail._id, audit_enable: isAudit });
                    }
                    break;
                }
            }
        }

        let newTracking = importedDataArray.filter(item => !findNewTrackingEntry(item, trackingDataArray));
        if (newTracking.length > 0) {
            newTracking.forEach((item) => {
                newDiffreneces.push({ ...item, user_id: userDetail._id, audit_enable: isAudit });
            })
        }

        return [...differences, ...newDiffreneces];
    }

    const isDifferent = (obj1, obj2) => {
        let dataDifferent = false;
        for (let dataKey in obj1.data) {
            if (obj1.data[dataKey] !== obj2.data[dataKey]) {
                dataDifferent = true;
                break;
            }
        }
        if (dataDifferent === false) {
            for (let dataKey in obj2.data) {
                if (obj2.data[dataKey] !== obj1.data[dataKey]) {
                    dataDifferent = true;
                    break;
                }
            }
        }
        return dataDifferent;
    }

    const findNewTrackingEntry = (item, array) => {
        return array.some(obj =>
            obj.candidate_id === item.candidate_id &&
            obj.step_id === item.step_id
        );
    }

    const handleDocumentNavigation = async (direction) => {
        setIsLoading(true);
        setDocFormNavigationForward(direction === "next");
    
        const currentIndex = candidateData.findIndex((item) => item._id === formCandidateId);
        const targetCandidate = direction === "next" ? candidateData[currentIndex + 1] : candidateData[currentIndex - 1];
    
        if (targetCandidate) {
            const candidateIdObj = { candidateId: targetCandidate._id };
    
            const stepData = trackingData.filter(trackData => 
                trackData.candidate_id === targetCandidate._id && trackData.step_id === DOCUMENTATION_STEP_ID
            );
    
            const docStep = { step_id: DOCUMENTATION_STEP_ID, stepTitle: "Documentation" };
    
            setFormCandidateId(targetCandidate._id);
            await handleStepComplete(candidateIdObj, stepData, docStep);
        } else {
            ShowToast(`No ${direction === "next" ? "Next" : "Previous"} Candidate`, TYPE_INFO, INTERVAL, "", "");
        }
    
        setIsLoading(false);
    };
    
    return (<>
        {
            isLoading === true ? (
                <Spinner />
            ) : (
                <div className="main-content">
                    <div className="back bradcrumb-sides">
                        <ul className="breadcrumb1 left-side">
                            <li><a href="/project-list">Project</a></li>
                            <li>{data[0] ? data[0].program_name : ""}</li>
                        </ul>
                    </div>
                    <div className="tab-container">
                        {
                            phases.map((phase) => (
                                <button
                                    key={phase}
                                    onClick={() => handlePhaseChange(phase)}
                                    className={`tab-button ${selectedPhase === phase ? "selected" : ""}`}
                                >
                                    {phase}
                                </button>
                            ))
                        }
                        {
                            hasPermission("project_tracking", "project_complete") && (
                                <div className="import-button">
                                    {
                                        data.length > 0 && data[0].program_status && data[0].program_status !== 'Completed' && (
                                            <button className="brand-button width-auto ml-5" onClick={programComplete}>
                                                Project Complete
                                            </button>
                                        )}
                                </div>
                            )
                        }
                    </div>
                    <div className="list-wrapper mt-5">
                        <div className="form-button-div excel-export-btn-div">
                            {
                                hasPermission("project_tracking", "download_template") && (
                                    <button className="button-secondary sample-file-width" onClick={downloadTemplateExcel}>Sample File&nbsp;&nbsp;&nbsp;<i className={IMPORT_ICON} /></button>
                                )
                            }
                            {
                                trackingDataLoading === true ? (
                                    <div className="spinner-loader"></div>
                                ) : (
                                    <button className="button-secondary" onClick={exportToExcel}>Export&nbsp;&nbsp;&nbsp;<i className={IMPORT_ICON} /></button>
                                )
                            }

                            <input
                                type="file"
                                accept=".xlsx"
                                onChange={handleFileUpload}
                                style={{ display: "none" }}
                                ref={fileInputRef}
                            />
                            {
                                importLoading ? (
                                    <div className="spinner-loader margin-import-loader"></div>
                                ) : (
                                    <button className="button-secondary ml-5" onClick={importProgramTrackerExcel}>Import&nbsp;&nbsp;&nbsp;<i className={EXPORT_ICON} /></button>
                                )
                            }
                        </div>
                        <div>
                            <div className="mt-4 border list-table-div program-candidate-material-table-div">
                                {data && (
                                        <ThemeProvider theme={mytheme}>
                                            <MaterialTable
                                                columns={[
                                                    ...getDynamicColumns(data[0]?.workflow_steps || []),
                                                ]}

                                                data={candidateData.map((item, index) => {
                                                    return {
                                                        srNo: index + 1,
                                                        candidateId: `${item._id}`,
                                                        candidateName: `${item.first_name} ${item.last_name}`,
                                                        employerUnit: item.employer_unit,
                                                        trainingPartner: item.training_partner ? item.training_partner : "",
                                                        selectedDepartment: item.department_selected,
                                                        isDropout: item.isDropout,
                                                        droppedOutPhase: item.droppedOutPhase,
                                                        isFailedInterview: item.isFailedInterview,
                                                        ageBelowEighteen: item.ageBelowEighteen
                                                    }
                                                })}

                                                components={{
                                                    Toolbar: (props) => (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ flexGrow: 1 }}>
                                                                <MTableToolbar {...props} />
                                                            </div>
                                                            {
                                                                hasPermission("user", "audit") &&
                                                                <AuditReport isFromTracking={true} programId={programId} programData={data} candidateData={candidateData} stepData={stepsForms} />
                                                            }
                                                        </div>
                                                    ),
                                                }}

                                                options={{
                                                    actionsColumnIndex: -1,
                                                    headerStyle: { color: '#999998', fontWeight: 'bolder', fontSize: 15, textAlign: 'center' },
                                                    sorting: true,
                                                    columnsButton: true,
                                                    exportButton: true,
                                                    exportFileName: "Program Data",
                                                    rowStyle: (rowData) => {
                                                        return {
                                                            backgroundColor: rowData.ageBelowEighteen && BELOW_EIGHTEEN_COLOR,
                                                        };
                                                    }
                                                }}
                                            />
                                        </ThemeProvider>
                                )}
                                {
                                    showForm && (
                                        <div className="custom_modal">
                                            <div className="modal_body">
                                                <div className="sticky-header">
                                                    <button type="button" className="close text-right" onClick={() => setShowForm(false)}>&times;</button>
                                                    <h5 className="form-heading">
                                                        {`Candidate Name : ${candidateData.find(candidate => candidate._id === formCandidateId)?.first_name || ""} ${candidateData.find(candidate => candidate._id === formCandidateId)?.last_name || ""}`}
                                                    </h5>
                                                </div>
                                                <Form
                                                    schema={formData[0].form_schema}
                                                    uiSchema={
                                                        documentStepsFlag ?
                                                            setDocumentStepsSchema(formData)
                                                            : (formDisable ?
                                                                hideSubmitButton
                                                                : (isUploadFileStep ?
                                                                    setDocumentStepsSchema(formData, true)
                                                                    : formData[0].ui_schema))
                                                    }
                                                    validator={validator}
                                                    onSubmit={handleSubmitForm}
                                                    formData={viewStepData}
                                                    disabled={formDisable}
                                                >
                                                    {
                                                        <>
                                                            <div className="form-navigation-buttons">
                                                                <div>
                                                                    {
                                                                        !formDisable && (
                                                                            <button
                                                                                type="submit"
                                                                                className="submit-button"
                                                                                disabled={formDisable || isSubmitting}
                                                                            >
                                                                                {isSubmitting ? <span>Saving...</span> : "Submit"}
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        formData[0]._id === DOCUMENTATION_STEP_ID && (
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="submit-button"
                                                                                    onClick={() => handleDocumentNavigation("prev")}
                                                                                >
                                                                                    Prev
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="submit-button ml-2"
                                                                                    onClick={() => handleDocumentNavigation("next")}
                                                                                >
                                                                                    Next
                                                                                </button>
                                                                            </>
                                                                        )

                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </Form>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        <ImportErrorPopup modelOpen={isErrorModelOpen} closeModel={cancelHandle} candidateImportData={candidateImportData} />
        {
            compareModal && <CompareDataModal changedFields={candidateCompare} closeModel={cancelHandleCompare} saveCandidateData={saveCandidateData} />
        }
    </>
    );
};

export default ProgramTracking;