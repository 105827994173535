import React, { useState } from "react";

const FileUploadWidget = (props) => {
  const { onChange } = props;
  const [fileName, setFileName] = useState("");

  const allowedTypes = ["pdf", "png", "jpg", "jpeg"];
  const maxSize = 2 * 1024 * 1024; // 2 MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const fileType = file.name.split(".").pop().toLowerCase();

    if (!allowedTypes.includes(fileType)) {
      alert("Invalid file type! Only PDF, PNG, JPG, and JPEG are allowed.");
      return;
    }

    if (file.size > maxSize) {
        alert("File size exceeds the 2 MB limit. Please upload a smaller file.");
        return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (onChange) {
        onChange(reader.result);
      }
      setFileName(file.name);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <input type="file" accept=".pdf,.png,.jpg,.jpeg" onChange={handleFileChange} />
      {fileName && <p>✅ {fileName} uploaded successfully.</p>}
    </div>
  );
};

export default FileUploadWidget;