import React, { useEffect, useState, useRef } from "react";
import "./tooltipcss.css";

const InfoToolTip = (props) => {
    const [showToolTip, setShowToolTip] = useState(false);
    const toolTipRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    const handleClickOutside = (event) => {
        if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
            setShowToolTip(false);
        }
    }

    return (
        <>
            <span onClick={() => setShowToolTip(!showToolTip)} className="ml-2 i-icon-tooltip">i</span>
            {
                showToolTip && (
                    <span ref={toolTipRef} className="i-tooltip">{props.info}</span>
                )
            }
        </>
    )
}

export default InfoToolTip;