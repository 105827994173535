import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useHistory } from 'react-router-dom';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from 'moment';
import { EXPORT_ICON } from "../assets/constants/Icons";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

const MaterialTableEmployer = (props) => {
    const [dataList, setDataList] = useState(props.employerTab ? props.unitDataList : props.partnerDataList);
    const history = useHistory();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const mytheme =  createTheme({});
    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        setDataList(props.employerTab ? props.unitDataList : props.partnerDataList)
    }, [props])

    const tableOptions = {
        actionsColumnIndex: -1,
        headerStyle: {
            color: '#999998',
            fontWeight: 'bolder',
            fontSize: 15
        },
        sorting: true,
        filtering: true,
        columnsButton: true,
        exportButton: true,
        exportFileName: props.employerTab ? "Admin Employer List" : "Admin Partner List",
        tableLayout: "fixed",
        search: false
    };

    const handleDashboardClick = (rowData) => {
        let employerOrPartnerId = rowData.partnerId;
        if (props && props.employerTab === true) {
            employerOrPartnerId = rowData.employerId;
            history.push("/employer-dashboard-page", employerOrPartnerId);
        }
        else {
            history.push("/partner-dashboard-page", employerOrPartnerId);
        }
    }

    useEffect(() => {
        const createTableColumns = () => {
            let tableColumnsTemp = [
                {
                    title: `${props.employerTab ? "Unit Name" : "Training Parnter"}`, field: `${props.employerTab ? "unitName" : "trainingPartner"}`,
                    cellStyle: { minWidth: 150, maxWidth: 150 },
                    render: (rowData) => (
                        <label className="cursor-pointer brand-color" onClick={() => handleDashboardClick(rowData)}>{props.employerTab ? rowData.unitName : rowData.trainingPartner}</label>
                    ),
                    hidden: props.projectType === "csr" && props.employerTab === true ? true : false
                },
                { title: `${props.employerTab ? props.projectType === "csr" ? "CSR Company" : "Employer Name" : "Project Name"}`, field: `${props.employerTab ? "employerName" : "partnerName"}`, cellStyle: { minWidth: 150, maxWidth: 150 } },
            ];
            if (props && props.employerTab === false) {
                tableColumnsTemp.push({ title: "Service Offered", field: "serviceOffered" });
            }
            const tempColumns = [
                {
                    title: "City", field: "city", cellStyle: { minWidth: 150, maxWidth: 150 },
                    hidden: props.projectType === "csr" && props.employerTab === true ? true : false
                },
                { title: "Total Projects", field: "totalProjectCount" },
                { title: "Active Projects", field: "activeProjectCount" },
                { title: "Completed Project", field: "completeProjectCount" },
                { title: "Candidates Expected", field: "totalCandidateExpected" },
                { title: "Candidates Listed", field: "totalCandidateCandidateListed" },
                { title: "Candidates Assigned", field: "totalCandidateAssigned" },
                { title: "Candidates Delivered", field: "candidateDelivered" },
                { title: "Candidates Dropout", field: "candidateDropout" },
                { title: "Post Placement Dropout", field: "totalCandidatePostDeliverDropout" }
            ];
            tableColumnsTemp = [...tableColumnsTemp, ...tempColumns];
            setTableColumns(tableColumnsTemp);
        }
        createTableColumns();
        // eslint-disable-next-line
    }, [props]);

    const handleFilterChange = (filter) => {
        props.handleFilterChange(filter);
    }

    const updateTotalsByFilteredResult = (newUnitDataList) => {
        let totalProject = [];
        let totalProjectVariables = {};
        let totalCandidateAssigned = 0;
        let totalCandidateExpected = 0;
        let candidateDelivered = 0;
        let candidateDropout = 0;
        let totalMaleCandidate = 0;
        let totalFemaleCandidate = 0;
        let totalPostDeliverDropout = 0;
        let totalCandidateListed = 0;
        newUnitDataList.forEach((listItem, index) => {
            let projectIdArray = [...listItem.projectId];
            projectIdArray = JSON.parse(JSON.stringify(projectIdArray));
            projectIdArray.forEach((item) => {
                const findObject = totalProject.find((findItem) => findItem === item);
                if (!findObject) {
                    totalProject.push(item);
                }
            });
            totalCandidateAssigned = totalCandidateAssigned + listItem.totalCandidateAssigned;
            totalCandidateExpected = totalCandidateExpected + listItem.totalCandidateExpected;
            candidateDelivered = candidateDelivered + listItem.candidateDelivered;
            candidateDropout = candidateDropout + listItem.candidateDropout;
            totalMaleCandidate = totalMaleCandidate + listItem.totalMaleCount;
            totalFemaleCandidate = totalFemaleCandidate + listItem.totalFemaleCount;
            totalCandidateListed = totalCandidateListed + listItem.totalCandidateCandidateListed;
            totalPostDeliverDropout = totalPostDeliverDropout + listItem.totalCandidatePostDeliverDropout;            ;
        })
        let totalCompleteProject = 0;
        totalProject.forEach((projectItem) => {
            const findProject = props.projectList.find((findItem) => findItem._id === projectItem);
            if (findProject) {
                if (findProject.program_status === "Completed") {
                    totalCompleteProject++;
                }
            }
        })

        totalProjectVariables = {
            totalCompleteProject: totalCompleteProject,
            totalActiveProject: totalProject.length - totalCompleteProject,
            totalCandidateAssigned: totalCandidateAssigned,
            totalCandidateExpected: totalCandidateExpected,
            candidateDelivered: candidateDelivered,
            candidateDropout: candidateDropout,
            totalMaleCandidate: totalMaleCandidate,
            totalFemaleCandidate: totalFemaleCandidate,
            totalCandidateListed: totalCandidateListed,
            totalPostDeliverDropout: totalPostDeliverDropout
        }
        let updateTotalsList = [];
        const subsetTotalsObject = {
            rowName: "Subset Totals",
            totalUnits: newUnitDataList.length,
            totalActiveProject: totalProjectVariables.totalActiveProject,
            totalProjectCount: totalProject.length,
            totalProjectCompletedCount: totalProjectVariables.totalCompleteProject,
            totalCandidateExpected: totalProjectVariables.totalCandidateExpected,
            totalCandidateDelivered: totalProjectVariables.candidateDelivered,
            totalAssignedCandidate: totalProjectVariables.totalCandidateAssigned,
            totalCandidateDropout: candidateDropout,
            totalMaleCandidate: totalMaleCandidate,
            totalFemaleCandidate: totalFemaleCandidate,
            totalCandidateListed: totalCandidateListed,
            totalPostDeliverDropout: totalPostDeliverDropout            
        }
        updateTotalsList.push(subsetTotalsObject);
        const percentageTotals = {
            rowName: "Percentage",
            totalActiveProject: subsetTotalsObject && subsetTotalsObject.totalActiveProject !== 0 ? Math.round((subsetTotalsObject.totalActiveProject / subsetTotalsObject.totalProjectCount) * 100) : 0,
            totalProjectCompletedCount: subsetTotalsObject && subsetTotalsObject.totalProjectCompletedCount !== 0 ? Math.round((subsetTotalsObject.totalProjectCompletedCount / subsetTotalsObject.totalProjectCount) * 100) : 0,
            totalCandidateDelivered: subsetTotalsObject && subsetTotalsObject.totalCandidateDelivered !== 0 ? Math.round((subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalCandidateExpected) * 100) : 0,
            totalCandidateDropout: subsetTotalsObject && subsetTotalsObject.totalCandidateDropout !== 0 ? Math.round((subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalMaleCandidate: subsetTotalsObject && subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalFemaleCandidate: subsetTotalsObject && subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalAssignedCandidate: subsetTotalsObject && subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalAssignedCandidate / subsetTotalsObject.totalCandidateExpected) * 100) : 0,
            totalPostDeliverDropout: subsetTotalsObject && subsetTotalsObject.totalPostDeliverDropout !== 0 ? Math.round((subsetTotalsObject.totalPostDeliverDropout / subsetTotalsObject.totalCandidateDelivered) * 100) : 0
        }
        updateTotalsList.push(percentageTotals);
        const averageProject = {
            rowName: "Average Per Project",
            totalCandidateExpected: subsetTotalsObject && subsetTotalsObject.totalCandidateExpected !== 0 ? Math.round(subsetTotalsObject.totalCandidateExpected / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDelivered: subsetTotalsObject && subsetTotalsObject.totalCandidateDelivered !== 0 ? Math.round(subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDropout: subsetTotalsObject && subsetTotalsObject.totalCandidateDropout !== 0 ? Math.round(subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalProjectCount) : 0,
            totalMaleCandidate: subsetTotalsObject && subsetTotalsObject.totalMaleCandidate !== 0 ? Math.round(subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalProjectCount) : 0,
            totalFemaleCandidate: subsetTotalsObject && subsetTotalsObject.totalFemaleCandidate !== 0 ? Math.round(subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalProjectCount) : 0
        }
        updateTotalsList.push(averageProject);
        return updateTotalsList;
    }

    const handleExportButtonClick = async (propsData) => {
        setDownloadLoading(true);
        let data = []
        if (props && props.employerTab === true) {
            if (propsData.data.length !== props.unitDataList.length) {
                data = updateTotalsByFilteredResult(propsData.data);
            } else {
                data = props.totalsList;
            }
        }
        else {
            if (propsData.data.length !== props.partnerDataList.length) {
                data = updateTotalsByFilteredResult(propsData.data);
            } else {
                data = props.totalsList;
            }
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        let columns = [
            { header: "", key: "rowName" },
        ];
        if ((props && props.projectType !== "csr") || props.employerTab === false) {
            columns.push({ header: `${props.employerTab ? "No of Units" : "No of Parnters"}`, key: "totalUnits" })
        }
        const tempColumns = [
            { header: "Total Projects", key: "totalProjectCount" },
            { header: "Active Projects", key: "totalActiveProject" },
            { header: "Completed Projects", key: "totalProjectCompletedCount" },
            { header: "Candidates Expected", key: "totalCandidateExpected" },
            { header: "Candidates Listed", key: "totalCandidateListed" },
            { header: "Candidates Assigned", key: "totalAssignedCandidate" },
            { header: "Candidates Delivered", key: "totalCandidateDelivered" },
            { header: "Candidates Dropout", key: "totalCandidateDropout" },
            { header: "Post Placement Dropout", key: "totalPostDeliverDropout" },
            { header: "Male Candidate", key: "totalMaleCandidate" },
            { header: "Female Candidates", key: "totalFemaleCandidate" }
        ]
        columns = [...columns, ...tempColumns];
        data.forEach((row, rowIndex) => {
            const dataRow = [];
            if (rowIndex === 0) {
                columns.forEach((column, index) => {
                    if (index === 0) {
                        if (props && props.employerTab === true) {
                            if (props && props.projectType === "csr") {
                                dataRow.push("CSR Project Totals");
                            }
                            else {
                                dataRow.push("Employer Totals");
                            }
                        }
                        else {
                            if (props && props.projectType === "csr") {
                                dataRow.push("CSR Project Totals");
                            }
                            else {
                                dataRow.push("Partner Totals");
                            }
                        }
                    }
                    else {
                        dataRow.push("");
                    }
                });
            }
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        const mainHeaderRow = worksheet.addRow(columns.map((column) => column.header));
        mainHeaderRow.font = { bold: true };
        mainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };

        data.forEach((row, index) => {
            const dataRow = [];
            columns.forEach((column) => {
                if (index === 1 && row[column.key] !== "Percentage") {
                    let tempValue = "";
                    if (row[column.key]) {
                        tempValue = `${row[column.key]} %`
                    }
                    dataRow.push(tempValue);
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };
        })

        worksheet.addRow("");
        worksheet.addRow("");
        const headerCell = worksheet.getCell(1, 1);
        headerCell.alignment = { vertical: "middle", horizontal: "center" };
        headerCell.font = { bold: true };
        headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };

        const listData = propsData.data;
        listData.forEach((row, rowIndex) => {
            const dataRow = [];
            if (rowIndex === 0) {
                columns.forEach((column, index) => {
                    if (index === 0) {
                        if (props && props.employerTab === true) {
                            if(props.projectType ==="csr"){
                                dataRow.push("CSR Company List");
                            }
                            else{
                                dataRow.push("Unit List");
                            }
                        }
                        else {
                            if(props.projectType ==="csr"){
                                dataRow.push("CSR Partner List");
                            }
                            else{
                                dataRow.push("Partner List");
                            }
                        }
                    }
                    else {
                        dataRow.push("");
                    }
                });
            }
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let listColumns = []
        propsData.columns.forEach((item) => {
            if (!item.hidden) {
                listColumns.push({ header: item.title, key: item.field });
            }
        })

        const listMainHeaderRow = worksheet.addRow(listColumns.map((column) => column.header));
        listMainHeaderRow.font = { bold: true };
        listMainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };

        listData.forEach((row) => {
            const dataRow = [];
            listColumns.forEach((column) => {
                dataRow.push(row[column.key]);
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };
        })

        const listHeaderCell = worksheet.getCell(8, 1);
        listHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
        listHeaderCell.font = { bold: true };
        listHeaderCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };

        worksheet.columns.forEach(column => {
            column.eachCell(cell => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const todayDate = moment(Date.now()).format("DD_MM_YYYY-HH_mm");
        let fileName = `${props && props.employerTab === true ? props.projectType ==="csr" ? "CSRCompanyTotals": "EmployerTotals" : props.projectType === "csr" ? "CSRPartnerTotals":"ParnterTotals"}${todayDate}.xlsx`;
        saveAs(new Blob([buffer]), fileName);
        setDownloadLoading(false)
    }

    return (<>
        <div className="mt-4 border list-table-div admin-employer-dashboard material-component-outer" style={{ marginBottom: 50 }}>
            <ThemeProvider theme={mytheme}>
                <MaterialTable
                    columns={tableColumns}
                    data={dataList.map((item, index) => ({
                        activeProjectCount: item.activeProjectCount,
                        candidateDelivered: item.candidateDelivered,
                        candidateDropout: item.candidateDropout,
                        city: item.city,
                        completeProjectCount: item.completeProjectCount,
                        employerName: item.employerName,
                        totalCandidateAssigned: item.totalCandidateAssigned,
                        totalCandidateExpected: item.totalCandidateExpected,
                        totalProjectCount: item.totalProjectCount,
                        unitName: item.unitName,
                        projectId: item.projectId,
                        partnerName: item.partnerName,
                        trainingPartner: item.trainingPartner,
                        employerId: item.employerId,
                        partnerId: item.partnerId,
                        totalMaleCount: item.totalMaleCount,
                        totalFemaleCount : item.totalFemaleCount,
                        serviceOffered : item.serviceOffered && item.serviceOffered.length > 0 ? item.serviceOffered.join(", ") : null,
                        totalCandidateCandidateListed: item.totalCandidateCandidateListed,
                        totalCandidatePostDeliverDropout: item.totalCandidatePostDeliverDropout
                    }))}
                    options={tableOptions}
                    onFilterChange={handleFilterChange}
                    components={{
                        Toolbar: (propsData) => (
                            <div>
                                <MTableToolbar {...propsData} />
                                {
                                    <div className="material-table-export-excel-outer-div">
                                        {
                                            downloadLoading === true ? (<>
                                                <div className="material-component-spinner-div">
                                                    <div className="spinner-loader material-spinner-loader"></div>
                                                </div>
                                            </>) : (
                                                <button className="material-table-export-excel" title="Export to Excel"
                                                    onClick={
                                                        () => {
                                                            handleExportButtonClick(propsData);
                                                        }
                                                    }
                                                >
                                                    <i className={EXPORT_ICON} />
                                                </button>

                                            )
                                        }

                                    </div>
                                }
                            </div>
                        ),
                    }}
                />
            </ThemeProvider>
        </div>
    </>)
}

export default MaterialTableEmployer;