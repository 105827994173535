import React, { useEffect, useState } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from 'moment';
import { EXPORT_ICON } from "../../assets/constants/Icons";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';

const MaterialTableProject = (props) => {
    const [dataList, setDataList] = useState(props.projectWiseDataList ? props.projectWiseDataList : []);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const mytheme = createTheme({});
    const [tableColumns, setTableColumns] = useState([]);
    useEffect(() => {
        setDataList(props.projectWiseDataList ? props.projectWiseDataList : []);
    }, [props])

    const tableOptions = {
        actionsColumnIndex: -1,
        headerStyle: {
            color: '#999998',
            fontWeight: 'bolder',
            fontSize: 15
        },
        sorting: true,
        filtering: true,
        columnsButton: true,
        exportButton: true,
        exportFileName: "Admin Project List",
        tableLayout: "fixed",
        search: false,
        paging: true,
        pageSize: 10,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        pageSizeOptions: [10, 20, 50],    // rows selection options

    };

    useEffect(() => {
        const createTableColumns = () => {
            let tableColumnsTemp = [
                {
                    title: 'Project Name',
                    field: 'program_name',
                    headerStyle: {
                        width: 500,
                        minWidth: 500,
                        maxWidth: 500,
                        whiteSpace: 'nowrap'
                    },
                    cellStyle: {
                        width: 500,
                        minWidth: 500,
                        maxWidth: 500,
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        hyphens: 'auto',
                    },
                    render: (rowData) => (
                        <a href={`program-tracking/${rowData.projectId}`} className="program-name-style">
                            {rowData.program_name}
                        </a>
                    ),
                }
            ];
            const tempColumns = [
                { title: "Service Offered", field: "serviceOffered" },
                { title: "Project Status", field: "programStatus"},
                { title: "Candidates Expected", field: "totalCandidateExpected" },
                { title: "Candidates Listed", field: "totalCandidateCandidateListed" },
                { title: "Candidates Assigned", field: "totalCandidateAssigned" },
                { title: "Total Male", field: "maleCount" },
                { title: "Total Female", field: "femaleCount" },
                { title: "Candidates Delivered", field: "candidateDelivered" },
                { title: "Candidates Dropout", field: "candidateDropout" },
                { title: "Post Placement Dropout", field: "totalCandidatePostDeliverDropout" }
            ];
            tableColumnsTemp = [...tableColumnsTemp, ...tempColumns];
            setTableColumns(tableColumnsTemp);
        }
        createTableColumns();
        // eslint-disable-next-line
    }, [props]);

    const handleFilterChange = (filter) => {
        props.handleFilterChange(filter);
    }

    const updateTotalsByFilteredResult = (newUnitDataList) => {
        let totalProjectVariables = {};
        let totalCandidateAssigned = 0;
        let totalCandidateExpected = 0;
        let candidateDelivered = 0;
        let candidateDropout = 0;
        let totalMaleCandidate = 0;
        let totalFemaleCandidate = 0;
        let totalPostDeliverDropout = 0;
        let totalCandidateListed = 0;
        let totalCompleteProject = 0;
        newUnitDataList.forEach((listItem, index) => {
            totalCandidateAssigned = totalCandidateAssigned + listItem.totalCandidateAssigned;
            totalCandidateExpected = totalCandidateExpected + listItem.totalCandidateExpected;
            candidateDelivered = candidateDelivered + listItem.candidateDelivered;
            candidateDropout = candidateDropout + listItem.candidateDropout;
            totalMaleCandidate = totalMaleCandidate + listItem.maleCount;
            totalFemaleCandidate = totalFemaleCandidate + listItem.femaleCount;
            totalCandidateListed = totalCandidateListed + listItem.totalCandidateCandidateListed;
            totalPostDeliverDropout = totalPostDeliverDropout + listItem.totalCandidatePostDeliverDropout;
            totalCompleteProject = totalCompleteProject + listItem.completeProject;
        })

        totalProjectVariables = {
            totalCompleteProject: totalCompleteProject,
            totalActiveProject: newUnitDataList.length - totalCompleteProject,
            totalCandidateAssigned: totalCandidateAssigned,
            totalCandidateExpected: totalCandidateExpected,
            candidateDelivered: candidateDelivered,
            candidateDropout: candidateDropout,
            totalMaleCandidate: totalMaleCandidate,
            totalFemaleCandidate: totalFemaleCandidate,
            totalCandidateListed: totalCandidateListed,
            totalPostDeliverDropout: totalPostDeliverDropout
        }
        let updateTotalsList = [];
        const subsetTotalsObject = {
            rowName: "Subset Totals",
            totalUnits: newUnitDataList.length,
            totalActiveProject: totalProjectVariables.totalActiveProject,
            totalProjectCount: newUnitDataList.length,
            totalProjectCompletedCount: totalProjectVariables.totalCompleteProject,
            totalCandidateExpected: totalProjectVariables.totalCandidateExpected,
            totalCandidateDelivered: totalProjectVariables.candidateDelivered,
            totalAssignedCandidate: totalProjectVariables.totalCandidateAssigned,
            totalCandidateDropout: candidateDropout,
            totalMaleCandidate: totalMaleCandidate,
            totalFemaleCandidate: totalFemaleCandidate,
            totalCandidateListed: totalCandidateListed,
            totalPostDeliverDropout: totalPostDeliverDropout
        }
        updateTotalsList.push(subsetTotalsObject);
        const percentageTotals = {
            rowName: "Percentage",
            totalActiveProject: subsetTotalsObject && subsetTotalsObject.totalActiveProject !== 0 ? Math.round((subsetTotalsObject.totalActiveProject / subsetTotalsObject.totalProjectCount) * 100) : 0,
            totalProjectCompletedCount: subsetTotalsObject && subsetTotalsObject.totalProjectCompletedCount !== 0 ? Math.round((subsetTotalsObject.totalProjectCompletedCount / subsetTotalsObject.totalProjectCount) * 100) : 0,
            totalCandidateDelivered: subsetTotalsObject && subsetTotalsObject.totalCandidateDelivered !== 0 ? Math.round((subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalCandidateExpected) * 100) : 0,
            totalCandidateDropout: subsetTotalsObject && subsetTotalsObject.totalCandidateDropout !== 0 ? Math.round((subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalMaleCandidate: subsetTotalsObject && subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalFemaleCandidate: subsetTotalsObject && subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalAssignedCandidate) * 100) : 0,
            totalAssignedCandidate: subsetTotalsObject && subsetTotalsObject.totalAssignedCandidate !== 0 ? Math.round((subsetTotalsObject.totalAssignedCandidate / subsetTotalsObject.totalCandidateExpected) * 100) : 0,
            totalPostDeliverDropout: subsetTotalsObject && subsetTotalsObject.totalPostDeliverDropout !== 0 ? Math.round((subsetTotalsObject.totalPostDeliverDropout / subsetTotalsObject.totalCandidateDelivered) * 100) : 0
        }
        updateTotalsList.push(percentageTotals);
        const averageProject = {
            rowName: "Average Per Project",
            totalCandidateExpected: subsetTotalsObject && subsetTotalsObject.totalCandidateExpected !== 0 ? Math.round(subsetTotalsObject.totalCandidateExpected / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDelivered: subsetTotalsObject && subsetTotalsObject.totalCandidateDelivered !== 0 ? Math.round(subsetTotalsObject.totalCandidateDelivered / subsetTotalsObject.totalProjectCount) : 0,
            totalCandidateDropout: subsetTotalsObject && subsetTotalsObject.totalCandidateDropout !== 0 ? Math.round(subsetTotalsObject.totalCandidateDropout / subsetTotalsObject.totalProjectCount) : 0,
            totalMaleCandidate: subsetTotalsObject && subsetTotalsObject.totalMaleCandidate !== 0 ? Math.round(subsetTotalsObject.totalMaleCandidate / subsetTotalsObject.totalProjectCount) : 0,
            totalFemaleCandidate: subsetTotalsObject && subsetTotalsObject.totalFemaleCandidate !== 0 ? Math.round(subsetTotalsObject.totalFemaleCandidate / subsetTotalsObject.totalProjectCount) : 0
        }
        updateTotalsList.push(averageProject);
        return updateTotalsList;
    }

    const handleExportButtonClick = async (propsData) => {
        setDownloadLoading(true);
        let data = []
        if (props && props.projectTab === true) {
            if (propsData.data.length !== props.projectWiseDataList.length) {
                data = updateTotalsByFilteredResult(propsData.data);
            } else {
                data = props.totalsList;
            }
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        let columns = [
            { header: "", key: "rowName" },
        ];
        const tempColumns = [
            { header: "Total Projects", key: "totalProjectCount" },
            { header: "Active Projects", key: "totalActiveProject" },
            { header: "Completed Projects", key: "totalProjectCompletedCount" },
            { header: "Candidates Expected", key: "totalCandidateExpected" },
            { header: "Candidates Listed", key: "totalCandidateListed" },
            { header: "Candidates Assigned", key: "totalAssignedCandidate" },
            { header: "Candidates Delivered", key: "totalCandidateDelivered" },
            { header: "Candidates Dropout", key: "totalCandidateDropout" },
            { header: "Post Placement Dropout", key: "postDeliveredDropout" },
            { header: "Male Candidate", key: "totalMaleCandidate" },
            { header: "Female Candidates", key: "totalFemaleCandidate" }
        ]
        columns = [...columns, ...tempColumns];
        data.forEach((row, rowIndex) => {
            const dataRow = [];
            if (rowIndex === 0) {
                columns.forEach((column, index) => {
                    if (index === 0) {
                        if (props && props.projectTab === true) {
                            dataRow.push("Project Totals");
                        }
                    }
                    else {
                        dataRow.push("");
                    }
                });
            }
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        const mainHeaderRow = worksheet.addRow(columns.map((column) => column.header));
        mainHeaderRow.font = { bold: true };
        mainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };

        data.forEach((row, index) => {
            const dataRow = [];
            columns.forEach((column) => {
                if (index === 1 && row[column.key] !== "Percentage") {
                    let tempValue = "";
                    if (row[column.key]) {
                        tempValue = `${row[column.key]} %`
                    }
                    dataRow.push(tempValue);
                }
                else {
                    dataRow.push(row[column.key]);
                }
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };
        })

        worksheet.addRow("");
        worksheet.addRow("");
        const headerCell = worksheet.getCell(1, 1);
        headerCell.alignment = { vertical: "middle", horizontal: "center" };
        headerCell.font = { bold: true };
        headerCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };

        const listData = propsData.data;
        listData.forEach((row, rowIndex) => {
            const dataRow = [];
            if (rowIndex === 0) {
                columns.forEach((column, index) => {
                    if (index === 0) {
                        if (props && props.projectTab === true) {
                            dataRow.push("Project List");
                        }
                    }
                    else {
                        dataRow.push("");
                    }
                });
            }
            if (dataRow.length > 0) {
                worksheet.addRow(dataRow).font = { bold: true };
            }
        })
        let listColumns = []
        propsData.columns.forEach((item) => {
            if (!item.hidden) {
                listColumns.push({ header: item.title, key: item.field });
            }
        })

        const listMainHeaderRow = worksheet.addRow(listColumns.map((column) => column.header));
        listMainHeaderRow.font = { bold: true };
        listMainHeaderRow.alignment = { vertical: "middle", horizontal: "center" };

        listData.forEach((row) => {
            const dataRow = [];
            listColumns.forEach((column) => {
                dataRow.push(row[column.key]);
            });
            worksheet.addRow(dataRow).alignment = { vertical: "middle", horizontal: "center" };
        })

        const listHeaderCell = worksheet.getCell(8, 1);
        listHeaderCell.alignment = { vertical: "middle", horizontal: "center" };
        listHeaderCell.font = { bold: true };
        listHeaderCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ff4aafb2" }
        };

        worksheet.columns.forEach(column => {
            column.eachCell(cell => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const todayDate = moment(Date.now()).format("DD_MM_YYYY-HH_mm");
        let fileName = `ProjectTotals-${todayDate}.xlsx`;
        saveAs(new Blob([buffer]), fileName);
        setDownloadLoading(false)
    }

    return (<>
        <div className="mt-4 border list-table-div admin-employer-dashboard material-component-outer width-100 project-wise-dashboard-div" style={{ marginBottom: 50 }}>
            <ThemeProvider theme={mytheme}>
                <MaterialTable
                    columns={tableColumns}
                    data={dataList.map((item, index) => ({
                        candidateDelivered: item.candidateDelivered,
                        candidateDropout: item.candidateDropout,
                        totalCandidateAssigned: item.candidateAssigned,
                        totalCandidateExpected: item.candidateExpected,
                        projectId: item._id,
                        partnerName: item.partnerName,
                        trainingPartner: item.trainingPartner,
                        maleCount: item.totalMale,
                        femaleCount: item.totalFemale,
                        totalCandidateCandidateListed: item.candidateListed,
                        totalCandidatePostDeliverDropout: item.postDeliveredDropout,
                        program_name: item.program_name,
                        serviceOffered: item.service_offered,
                        completeProject: item.completeProject,
                        activeProject: item.activeProject,
                        programStatus: item.program_status
                    }))}
                    options={tableOptions}
                    onFilterChange={handleFilterChange}
                    components={{
                        Toolbar: (propsData) => (
                            <div>
                                <MTableToolbar {...propsData} />
                                {
                                    <div className="material-table-export-excel-outer-div">
                                        {
                                            downloadLoading === true ? (<>
                                                <div className="material-component-spinner-div">
                                                    <div className="spinner-loader material-spinner-loader"></div>
                                                </div>
                                            </>) : (
                                                <button className="material-table-export-excel" title="Export to Excel"
                                                    onClick={
                                                        () => {
                                                            handleExportButtonClick(propsData);
                                                        }
                                                    }
                                                >
                                                    <i className={EXPORT_ICON} />
                                                </button>

                                            )
                                        }

                                    </div>
                                }
                            </div>
                        ),
                    }}
                />
            </ThemeProvider>
        </div>
    </>)
}

export default MaterialTableProject;