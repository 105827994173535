import axios from 'axios';
import Toast from "light-toast";
import { API_BASE_URL } from "assets/constants/Constants";
import ShowToast from "components/ShowToast";
import { TYPE_FAIL, INTERVAL } from "assets/constants/Constants";

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE ="DELETE";

let lastToastTimestamp = 0;

const showToastOnce = (message, duration, errorCode) => {
    const currentTimestamp = Date.now();

    // Check if enough time has passed since the last toast
    if (currentTimestamp - lastToastTimestamp >= duration) {
        lastToastTimestamp = currentTimestamp;
        if (errorCode === 403 || errorCode === 401) {
            Toast.fail(message, 3000, () => {
                localStorage.clear();
                window.location.href = "/login-page";
            });
        } else {
            ShowToast(message, TYPE_FAIL, INTERVAL, "", "");
        }
    }
};

const httpHandler = async (url, method, additionalHeaders, body) => {
    const commonHeaders = {
        'Accept': '*/*',
        'Content-Type': 'application/json'
    };

    if (url !== "login") {
        commonHeaders['Authorization'] = `Bearer ${localStorage.getItem("JWToken")}`;
    }

    const headers = Object.assign({}, commonHeaders, additionalHeaders);

    const axiosOptions = {
        method: method,
        headers: headers,
        data: JSON.stringify(body),
        url: `${API_BASE_URL}/${url}`,
    };

    try {
        if (navigator.onLine) {
            const resp = await axios(axiosOptions);
            const response = { statusCode: resp.status, data: await resp.data };

            switch (response.statusCode) {
                case 200:
                    return Object.assign(response, { status: "SUCCESS" });
                case 500:
                    return Object.assign(response, { status: "WARNING", message: "Something went wrong. Please try again later." });
                default:
                    return response;
            }
        } else {
            // Handle internet connection error
            showToastOnce("No internet connection", INTERVAL);
            return { statusCode: 0, status: "ERROR", message: "No internet connection" };
        }

    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 403:
                    showToastOnce("Session expired. Redirecting to login page...", INTERVAL, 403);
                    break;

                case 401:
                    showToastOnce("Unauthorized. Redirecting to login page...", INTERVAL, 401);
                    break;
                
                case 404:
                    showToastOnce("Resource not found", INTERVAL, 404);
                    break;
                
                case 429:
                    showToastOnce("Too Many Requests. Please try again later.", INTERVAL, 429);
                    break;

                default:
                    showToastOnce("Network error", INTERVAL, null);
                    break;
            }
        } else if (error.request) {
            showToastOnce("Internal Server Error. Please try again later" , INTERVAL, 500);
        } else {
            showToastOnce("Unexpected error", INTERVAL, null);
        }

        return { statusCode: 0, status: "ERROR", message: "Network error or other issues" };
    }
}

export const getProjectTotalForAdmin = async (additionalHeaders, body, projectType, selectedYear, selectedMonth) => {
    const url = `getProjectTotalForAdmin/${projectType}/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getCandidateTotalForAdmin = async (additionalHeaders, body, selectedYear, selectedMonth) => {
    const url = `getCandidateTotalForAdmin/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getEmployerList = async (additionalHeaders, body) => {
    const url = `employer/list`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
} 

export const getPartnerList = async (additionalHeaders, body) => {
    const url = `partner/list`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
} 

export const getCandidateList = async (additionalHeaders, body) => {
    const url = `candidate/list`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
} 

export const deleteCandidate = async (additionalHeaders, body, candidateId) => {
    const url = `candidate/${candidateId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
} 

export const createCandidateCall = async (additionalHeaders, body) => {
    const url = `candidate`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
} 

export const updateCandidateCall = async (additionalHeaders, body,candidateID) => {
    const url = `candidate/${candidateID}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const getFieldData = async (additionalHeaders, body) => {
    const url = `datacapture/list`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createFieldData = async (additionalHeaders, body) => {
    const url = `datacapture`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const updateFieldData = async (additionalHeaders, body,fieldId) => {
    const url = `datacapture/${fieldId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
} 

export const deleteFieldData = async (additionalHeaders, body,fieldId) => {
    const url = `datacapture/${fieldId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const createStep = async (additionalHeaders, body) => {
    const url = `workflowsteps`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const updateStep = async (additionalHeaders, body) => {
    const url = `workflowsteps/${body.stepId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const getStepList = async (additionalHeaders, body) => {
    const url = `workflowsteps/list`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const deleteStep = async (additionalHeaders, body,stepId) => {
    const url = `workflowsteps/${stepId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const createEmployerCall = async (additionalHeaders, body) => {
    const url = `employer`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const updateEmployerCall = async (additionalHeaders, body, employerId) => {
    const url = `employer/${employerId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const deleteEmployer = async (additionalHeaders, body,employerId) => {
    const url = `employer/${employerId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const fetchEmployerTotalCall = async (additionalHeaders, body, employerId) => {
    const url = `employerDashboard/${employerId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const forgotPasswordCall = async (additionalHeaders, body) => {
    const url = `user/forgetPassword`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getRoleCall = async (additionalHeaders, body, role) => {
    const url = `role/${role}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getMetaDataCall = async (additionalHeaders, body) => {
    const url = `getMetadata`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createPartnerCall = async (additionalHeaders, body) => {
    const url = `partner`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const updatePartnerCall = async (additionalHeaders, body, partnerId) => {
    const url = `partner/${partnerId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
} 

export const getPartnerTotalCall = async (additionalHeaders, body, partnerId) => {
    const url = `partnerDashboard/${partnerId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const deletePartner = async (additionalHeaders, body,partnerId) => {
    const url = `partner/${partnerId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const updateProgramCall = async (additionalHeaders, body, programId) => {
    const url = `program/${programId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const createProgramCall = async (additionalHeaders, body) => {
    const url = `program`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const fetchCandidateByCandidateIdArray = async (additionalHeaders, body) => {
    const url = `candidate/candidateArray`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const deleteProgramCall = async (additionalHeaders, body,programId) => {
    const url = `program/${programId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const getProgramListByEmployer = async (additionalHeaders, body, employerId) => {
    const url = `program/listByEmployer/${employerId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getProgramList = async (additionalHeaders, body) => {
    const url = `program/list`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getEmployerAndPartnerList = async (additionalHeaders, body) => {
    const url = `program/employerAndParnter/partnerlist`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getProgramData = async (additionalHeaders, body,programId) => {
    const url = `program/${programId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getCandidateByEmployer = async (additionalHeaders, body) => {
    const url = `candidate/byEmployer`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getCandidatesByCandidateNames = async (additionalHeaders, body,programId) => {
    const url = `candidate/getCandidatesNames/${programId}`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getStepListByStepIdArray = async (additionalHeaders, body) => {
    const url = `workflowsteps/getStepsForms`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getProgramTrackerData = async (additionalHeaders, body, programId) => {
    const url = `programtracker/program/${programId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createProgramTrackerData = async (additionalHeaders, body) => {
    const url = `programtracker`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const saveTrackerExcelImportData = async (additionalHeaders, body) => {
    const url = `programtracker/excelImportData`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const sendDropoutEmail = async (additionalHeaders, body) => {
    const url = `candidate/sendDroppedEmail`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getRoleListCall = async (additionalHeaders, body) => {
    const url = `role`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createRoleCall = async (additionalHeaders, body) => {
    const url = `role`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const updateRoleCall = async (additionalHeaders, body, roleId) => {
    const url = `role/${roleId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const deleteRole = async (additionalHeaders, body,roleId) => {
    const url = `role/${roleId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const findUserNameCall = async (additionalHeaders, body, event) => {
    const url = `user/findUserName/${event}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createUserCall = async (additionalHeaders, body) => {
    const url = `user`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const UpdateUserCall = async (additionalHeaders, body, userId) => {
    const url = `user/${userId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const getUserList = async (additionalHeaders, body, event) => {
    const url = `user`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const deleteUserCall = async (additionalHeaders, body,userId) => {
    const url = `user/${userId}`;
    const response = await httpHandler(url, DELETE, additionalHeaders, body);
    return response;
}

export const refreshAccessTokenCall = async (additionalHeaders, body) => {
    const url = `refreshToken`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const createBulkCandidate = async (additionalHeaders, body) => {
    const url = `candidate/bulkCandidate`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const signInCall = async (additionalHeaders, body) => {
    const url = `login`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getPartnerTotalProjectForAdmin = async (additionalHeaders, body, projectType, selectedYear, selectedMonth) => {
    const url = `getPartnerTotalProjectForAdmin/${projectType}/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getEmployerTotalProjectForAdmin = async (additionalHeaders, body, projectType, selectedYear, selectedMonth) => {
    const url = `getEmployerTotalProjectForAdmin/${projectType}/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getPermissionMatrixCall = async (additionalHeaders, body) => {
    const url = `permissionmatrix`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createJobseeker = async (additionalHeaders, body) => {
    const url = `createJobseeker`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const checkEmailPresent = async (additionalHeaders, body, candidateEmail) => {
    const url = `candidate/isEmailPresent/${candidateEmail}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const checkAadharPresent = async (additionalHeaders, body, aadharNo) => {
    const url = `candidate/isAadharPresent/${aadharNo}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const resendEmailRegistration = async (additionalHeaders, body) => {
    const url = `candidate/resendRegistration`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getProgramListByPartner = async (additionalHeaders, body, partnerId) => {
    const url = `program/listByPartner/${partnerId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getCandidateByPartner = async (additionalHeaders, body) => {
    const url ="candidate/byPartner";
    const response = await httpHandler(url,POST, additionalHeaders, body);
    return response;
}

export const getStepData = async (additionalHeaders, body, stepId) => {
    const url =`workflowsteps/${stepId}`;
    const response = await httpHandler(url, GET, additionalHeaders,body);
    return response;
}

export const getTrackerDataByDifferentIds = async (additionalHeaders,body) => {
    const url = `programtracker/gettrackerdata`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getCandidateById = async (additionalHeaders, body, candidateId) =>{
    const url = `candidate/${candidateId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const updateProgramTrackerData = async (additionalHeaders, body, trackerId) => {
    const url = `programtracker/${trackerId}`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const getProgramCandidateList = async (additionalHeaders, body, programId) => {
    const url = `candidate/program/list/${programId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const createBulkEmployer = async (additionalHeaders, body) => {
    const url = `employer/bulkcreate`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getBulkCandidateByAadhaarArray = async (additionalHeaders, body) => {
    const url = `candidate/aadhaar`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const createBulkPartner = async (additionalHeaders, body) => {
    const url = `partner/bulkimport`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const candidateInterviewFailedUpdate = async (additionalHeaders, body) => {
    const url = `candidate/candidateInterviewFailedUpdate`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const candidateSelectFromFailedUpdate = async (additionalHeaders, body) => {
    const url = `candidate/candidateSelectFromFailedUpdate`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const resetPasswordByOtpCall = async (additionalHeaders, body) => {
    const url = `user/resetpassword/otp`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const resetPasswordByAdminCall = async (additionalHeaders, body) => {
    const url = `user/reset/password/by/admin`;
    const response = await httpHandler(url, PUT, additionalHeaders, body);
    return response;
}

export const getUserDetails = async (additionalHeaders, body, userId) => {
    const url = `user/${userId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const fetchProgramByProgramIdArray = async (additionalHeaders, body) => {
    const url = `program/getProgramDataByIdBatch`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getUserDataByIdBatch = async (additionalHeaders, body) => {
    const url = `user/getUserDataByIdBatch`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const getTrackingHistoryByUserId = async (additionalHeaders, body, userId) => {
    const url = `userTrackingHistory/list/${userId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getTrackingHistoryByProgramId = async (additionalHeaders, body, programId) => {
    const url = `userTrackingHistory/list/byprogram/${programId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getCandidateHistoryByCandidateId = async (additionalHeaders, body, candidateId) => {
    const url = `candidateHistory/candidate/${candidateId}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getYtdProjectTotalForAdmin = async (additionalHeaders, body, projectType, selectedYear, selectedMonth) => {
    const url = `getProjectTotalForAdminByYTD/${projectType}/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getYtdCandidateTotalForAdmin = async (additionalHeaders, body, selectedYear, selectedMonth) => {
    const url = `getCandidateTotalForAdminByYTD/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const revokeDropoutStatus = async (additionalHeaders, body) => {
    const url = `programtracker/revoke/dropout`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
} 

export const getProjectwiseAdminData = async (additionalHeaders, body, projectType, selectedYear, selectedMonth) => {
    const url = `getProjectTotalForAdmin/projectwise/${projectType}/${selectedYear}/${selectedMonth}`;
    const response = await httpHandler(url, GET, additionalHeaders, body);
    return response;
}

export const getS3PresignedUrl = async (additionalHeaders, body) => {
    const url = `generate-presigned-url`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
}

export const uploadDocumentBucket = async (additionalHeaders, body) => {
    const { url, file } = body;
    const response = await axios.put(url, file, { headers: additionalHeaders });
    return response;
};

export const downloadDocumentBucket = async (additionalHeaders, body) => {
    const url = `generate-download-url`;
    const response = await httpHandler(url, POST, additionalHeaders, body);
    return response;
};